import styled from "styled-components/macro";

import { useGameContext } from "context/GameContext";

const TurnTimerContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;

  background: red;
  color: white;
  padding: 0.25rem;
`;

// renders the timer
function renderTurnTimer(secsLeftInTurn) {

  if (secsLeftInTurn && typeof secsLeftInTurn === "number") {

    // 1:* if 60 seconds or more
    const minsLeft = Math.floor( secsLeftInTurn / 60 );
    const secsLeft = secsLeftInTurn % 60;
    const prettySecsLeft = secsLeft < 10
      ? `0${secsLeft}`
      : secsLeft;
    return `${minsLeft}:${prettySecsLeft}`;
  }
  else {
    return ("-:--");
  }
}

const TurnTimer = () => {
  
  // Get seconds left in the turn - countdown of this value handled by GameContext
  const { secsLeftInTurn } = useGameContext();

  return (
    <TurnTimerContainer>
      { renderTurnTimer(secsLeftInTurn) }
    </TurnTimerContainer>
  );
};

export default TurnTimer;
