import { getIdToken } from "firebase/auth";

import versionJSON from "version/version";
const { version } = versionJSON;

import activebuildconfig from "configs/activebuildconfig.json";
const APIRootUrl = activebuildconfig.API_ROOT_URL;

import { firebaseAuth } from "services/firebase";
import { talkka } from "services/logging";

/**
 * Utility to hit our API in a standardized way
 *
 * @param { string } endpoint - last segment of API endpoint hit (everything
 * that comes after /teach/)
 * @param { string } verb [optional] - http verb to use for request (PUT or
 * POST for current endpoints)
 * @param { Object } body [optional] - object to send as request body
 * @param { Boolean } retryOnFailure [optional] - If passed as true, will retry
 * the fetch once in the event of an error (front-end or back-end)
 * @returns full result object from Cloud Functions
 */
export const asyncFetchResult = async ({
  endpoint = "",
  verb = "PUT",
  body = {},
  retryOnFailure = false,
}) => {
  try {
    // If we didn't get an endpoint to hit, we can't do anything!
    if ( !endpoint ) {
      throw new Error("Must supply endpoint to asyncFetchResult");
    }

    // Grab token if we have a logged in user, otherwise is null
    const token = firebaseAuth.currentUser
      ? await getIdToken( firebaseAuth.currentUser )
      : null;

    // Dispatch request and get response
    const response = await fetch(`${ APIRootUrl }/play/${ endpoint }`, {
      method: verb,
      headers: {
        Accept: "application/json",
        "Content-type": "application/json",
      },
      body: JSON.stringify({
        token,
        // Attach current user info from localStorage - ensures CF has some user info, since anonymous users may not have token
        userInfo: localStorage.getItem("currentUser"),
        version,
        ...body,
      }),
    });
    
    // Extract data from response
    const result = await response.json();

    // If the back-end call failed and we have a retry flag, retry the call!
    if (
      !result.success
      && retryOnFailure
    ) {
      return await retryAsyncFetchResult({
        endpoint,
        verb,
        body,
      });
    }

    // Add status code to result
    result.status = response.status;

    // Return result so datastore controller can interpret it
    return result;
  }
  catch ( error ) {

    // If retry flag has been passed, retry once!
    if ( retryOnFailure ) {
      return await retryAsyncFetchResult({
        endpoint,
        verb,
        body,
        error,
      });
    }

    // To avoid an infinite loop, don't talkka.log a failure in saveLogEvent (since talkka.error calls saveLogEvent, which calls asyncFetchResult)
    if ( !endpoint.includes("save_log_event") ) {
      // Custom logger that outputs in DEV, but not STAGE or PROD
      // talkka.error(`Error in ${ verb } call to ${ endpoint }:`, error);
      talkka.error(`Error in ${ verb } call to ${ endpoint }:`, error);
    }
    else {
      // Still log something to the console in DEV environments so that engineers can see that something went wrong with a call to save_log_event -- talkka.log doesn't try to hit the back end
      talkka.log(`Error in ${ verb } call to ${ endpoint }:`, error);
    }

    // Return response-shaped object indicating failure
    return {
      success: false,
      // custom status indicates unknown error from this function - 900 puts us outside of standard http code response range, and 22 stands for BB = beepboop
      status: 922,
    };
  }
};

/**
 * Retry a failed call once
 *
 * @param { string } endpoint - last segment of API endpoint hit (everything
 * that comes after /teach/)
 * @param { string } verb [optional] - http verb to use for request (PUT or
 * POST for current endpoints)
 * @param { Object } body [optional] - object to send as request body
 * @param { Object } error [optional] - error (if any) that led to retry
 * @returns
 */
const retryAsyncFetchResult = async ({
  endpoint,
  verb,
  body,
  error,
}) => {

  // Log that a retry happened
  talkka.info(`Retrying ${verb} request to ${endpoint}${
    error
      ? ` due to front-end failure with messge: ${error.message}`
      : ""
  }`);

  // Re-call asyncFetchResult with the same info, but without retry flag, this time
  return await asyncFetchResult({
    endpoint,
    verb,
    body,
    retryOnFailure: false,
  });
};
