import {
  useEffect,
  useState,
} from "react";
import styled from "styled-components/macro";

import Button from "components/Button";

import { getCurrentUser } from "context/UserContext";

import { useOffers } from "hooks/useOffers";

import FindPartnerCountdown from "./FindPartnerCountdown";

const ClickToPlayInstructions = styled.p`
  color: ${props => props.playNowAlert ? props.theme.purple : "default"};
  font-size: ${({ playNowAlert }) => playNowAlert ? "1.25em" : "1em"};
  font-weight: ${({ playNowAlert }) => playNowAlert ? "500" : "400"};
  text-shadow: ${props => props.playNowAlert ? props.theme.textShadow : "none"};
`;

/**
 * Renders button to create offer, or 'wait for somebody to join' info
 */
const ClickToPlay = () => {

  /*
   * HOOKS
   */

  const {
    acceptOffer,
    createOffer,
    deleteOffer,
    offers,   
  } = useOffers();

  const user = getCurrentUser();

  /*
   * STATE
   */

  // Record whether a user has clicked "Play Now" -- if so, we're either in the process of waiting for someone to join our offer, or joining an existing offer
  const [ playNowWasClicked, setPlayNowWasClicked ] = useState( false );

  /*
   * USE EFFECT
   */

  useEffect(() => {
    // Add beforeunload listener to remove user's offer if user leaves app
    window.addEventListener("beforeunload", deleteOffer);

    // On unmount, remove unload listener
    return function matcherUnload() {
      window.removeEventListener("beforeunload", deleteOffer);
    };
  }, []);

  /*
   * HELPERS
   */

  // Either join an existing offer, or make a new offer for someone else to join
  const playNow = async () => {
    // Set flag in state - triggers "waiting" screen
    setPlayNowWasClicked( true );

    // For now, if we have any offers, just join the first one in the list
    if ( offers.length ) {
      // TODO: Smarter logic for matching offers

      // Just find the first offer in the array that hasn't yet been accepted
      const offerToAccept = offers.find( o => !o.player2 );

      // Join the acceptable offer, or create a new one
      if ( offerToAccept ) {
        const finalOffer = await acceptOffer( offerToAccept );

        // If we didn't successfully join the offer, create one, instead
        if ( finalOffer?.player2?.authId !== user.authId ) {
          createOffer();
        }
      }
      else {
        createOffer();
      }
    }
    // If there are no offers, create one!
    else {
      createOffer();
    }
  };

  /*
   * RENDER
   */

  return playNowWasClicked
    ? (
      <FindPartnerCountdown
        createOffer={ createOffer }
        deleteOffer={ deleteOffer }
      />
    )
    : (
      <div>
        <ClickToPlayInstructions playNowAlert={!!offers.length} >
          {offers.length
            ? "Someone is waiting for a partner! Click the button below to play right now"
            : "When you're ready, click the button below to play!"
          }
        </ClickToPlayInstructions>
        <Button
          buttonStyle={"display: block; margin: 0.25rem auto;"}
          disabled={ playNowWasClicked }
          name="playNowButton"
          onClick={ playNow }
          text="Play Now!"
        />
        <label htmlFor="playNowButton">
          Click the button to start a game
        </label>
      </div>
    );
};

export default ClickToPlay;
