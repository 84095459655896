import { getCurrentUser } from "context/UserContext";

// Takes turns object from GameContext state, and returns an object with score values for the game as a whole
export const scoreTurns = ( turns ) => {

  const user = getCurrentUser();

  return Object.values(turns)?.reduce( (scoreObject, turn) => {

    // If the turn was guessed correctly, increment values
    if ( turn.endState === "CORRECT") {
      scoreObject.totalCorrect++;
      scoreObject.scoreSequence += "1";

      if ( turn.guesser === user.authId ) {
        scoreObject.localCorrect++;
      }
      else {
        scoreObject.remoteCorrect++;
      }
    }
    // TODO: add some logic to not count as incorrect if word was only visible for X seconds
    // Note: For now, scoring game timeout as incorrect, since this almost always occurs after turn has been active for sufficient period of time
    // if it's not correct, mark as Incorrect
    else {
      scoreObject.totalIncorrect++;
      scoreObject.scoreSequence += "0";
    }

    return scoreObject;

  }, {
    totalCorrect: 0,
    localCorrect: 0,
    remoteCorrect: 0,
    totalIncorrect: 0,
    scoreSequence: "", // 1 for correct, 0 for incorrect
  });
};
