import { Fragment } from "react";
import {
  useEffect,
  useState,
} from "react";
import styled from "styled-components/macro";

import Button from "components/Button";

import { useGameContext } from "context/GameContext";

const ShareContainer = styled.div`
  button {
    display: block;
    margin: 0.5rem auto;
  }
`;

const ShareStringContainer = styled.p`
  background: #ddd;
  border: 1px solid #ccc;
  border-radius: ${({ theme }) => theme.borderRadius};
  box-shadow: ${({ theme }) => theme.boxShadow};
  display: block;
  filter: brightness(1.1);
  margin: 0 auto;
  padding: 1rem;
  width: 50%;
  min-width: 200px;
`;

/**
 * Render a copy/pastable message to share game results on social media
 */
const SocialShare = () => {
  
  const { score } = useGameContext();

  // Save the sharing string as an array of lines - to be joined by <br /> when displayed on page, and \n when copied with button
  const [ shareStringLines, setShareStringLines ] = useState( [] );

  // Interpret score's scoreSequence string to create a string of emoji representing correct ("1" in scoreSequence) or incorrect ("0")
  useEffect(() => {

    const lines = [];

    // TODO: restore date when it's tied to the Global Daily Game
    
    /*const date = new Date();
    
    // Remember that .getMonth is zero-indexed
    const rawMonth = date.getMonth() + 1;
    const displayMonth = rawMonth < 10
      ? `0${ rawMonth }`
      : rawMonth;*/

    lines.push( "My Talkkaround Results" ); //${date.getFullYear()}-${displayMonth}-${date.getDate()}:` );

    let scoreString = "";
    for ( const char of score.scoreSequence ) {
      // If correct, add green circle emoji
      if ( char === "1" ) {
        // Can basically plug in unicode values to String.fromCodePoint
        scoreString += String.fromCodePoint(0x1F7E2);
      }
      // If incorrect, add white circle emoji
      else {
        scoreString += String.fromCodePoint(0x26AB);
      }
    }

    lines.push( scoreString );

    lines.push( "https://play.beepboop.us" );

    lines.push( "#langtwt #learnSpanish #talkkaround #beepboop" );

    setShareStringLines( lines );

  }, [ score ]);

  return (
    <ShareContainer>
      <Button
        onClick={ () => navigator.clipboard.writeText(shareStringLines.join("\n")) }
        text="Share (copy to clipboard)"
      />
      <ShareStringContainer>
        { shareStringLines.map((line, index) => (
          <Fragment key={index}>
            { line }
            <br />
          </Fragment>
        ))}
      </ShareStringContainer>
    </ShareContainer>
  );
};

export default SocialShare;
