import { useLocation } from "react-router-dom";
import styled from "styled-components/macro";

const ModalBackground = styled.div`
  /* DISPLAY */
  display: flex;
  align-items: center;
  justify-content: center;

  /* POSITION */
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  background: linear-gradient(rgba(180,180,180,0.8),rgba(180,180,180,0.8));
  z-index: ${ props => props.isVisible ? 10 : -10 };
`;

const Modal = styled.div`
  background: white;
  border-radius: ${ props => props.theme.borderRadius };
  box-shadow: ${ props => props.theme.boxShadow };
  box-sizing: border-box;
  padding: 1rem;
  position: relative;
  width: 67vw;

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    width: 90%;
  }
`;

const CloseButton = styled.button`
  /* POSITION */
  position: absolute; // against Modal
  top: -1rem;
  right: -1rem;

  animation: closeButtonBoop 0.5s linear forwards;
  background: ${ props => props.theme.turquoise };
  border: 1px solid white;
  border-radius: ${ props => props.theme.borderRadius };
  color: white;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 500;
  padding: 0.5rem 1rem;
  text-shadow: ${ props => props.theme.textShadow };

  @keyframes closeButtonBoop {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.5);
    }
    100% {
      transform: scale(1);
    }
`;

const WaitForGameStartMessage = styled.article`
  animation: gameStartMessagePulse 2s linear infinite;
  background: ${ props => props.theme.turquoise };
  border: 1px solid white;
  border-radius: ${ props => props.theme.borderRadius };
  color: white;
  font-size: 1.1rem;
  font-weight: 500;
  margin: 0 auto;
  padding: 1rem;
  text-align: center;
  text-shadow: ${ props => props.theme.textShadow };
  width: 75%;

  @keyframes gameStartMessagePulse {
    0% {
      transform: scale(1);
    }
    25% {
      transform: scale(1.1);
    }
    50% {
      transform: scale(1);
    }
`;

// TODO: Use these components (and probably some JS) to animate open/close
const Section = styled.details`
    &[open] summary {
      color: ${({ theme }) => theme.purple };
    }
`;

const SectionTitle = styled.summary`
  color: blue;
  cursor: pointer;
  text-decoration: underline;
`;

const SectionContent = styled.div``;

/**
 * Helpful modal with game rules and tips
 */
const HowToPlayModal = ({
  hide,
  isLocked,
  isVisible,
}) => {

  const location = useLocation();

  const hideOnBackgroundClick = ( event ) => {
    if ( event.target.id === "howToPlayModalBackground" ) {
      hide();
    }
  };

  return (
    <ModalBackground
      id="howToPlayModalBackground"
      isVisible={ isVisible }
      onClick={ hideOnBackgroundClick }
    >
      <Modal>
        {isLocked
          ? (
            <WaitForGameStartMessage>
              This window will close when the game has loaded...
            </WaitForGameStartMessage>
          )
          : (
            <CloseButton onClick={ hide }>
              Close
            </CloseButton>
          )
        }
        <h2>How To Play</h2>
        <Section open={ location.pathname.startsWith("/lobby") }>
          <SectionTitle>In the Lobby</SectionTitle>
          <SectionContent>
            <ul>
              <li>
                You can choose to either create a game of your own (for someone else to join), or you can join a game that someone else has already created
              </li>
            </ul>
          </SectionContent>
        </Section>
        <Section>
          <SectionTitle>Starting the Game</SectionTitle>
          <SectionContent>
            <ul>
              <li>
                The game starts when you join someone else's game from the Lobby, or when someone else joins yours
              </li>
              <li>
                When the game starts, you'll be paired with another student who wants to learn Spanish by speaking (just like you!)
              </li>
              <li>
                You and your partner (the other player) are magically transported to the game room, where you can hear each other and see a screen with game details
              </li>
            </ul>
          </SectionContent>
        </Section>
        <Section open={ location.pathname.startsWith("/play/") }>
          <SectionTitle>Playing the Game</SectionTitle>
          <SectionContent>
            <ul>
              <li>
                In each turn, you'll either be the Guesser or the Hint-Giver. Your partner will take the other role
              </li>
              <li>
                The Hint-Giver sees a word (in Spanish, obvi), and, speaking in Spanish, will give hints to try to get the Guesser to guess the word
              </li>
              <li>
                When the turn ends, you and your partner will switch roles: If you were the Hint-Giver for the first turn, you'll be the Guesser for the second (and vice-versa)
              </li>
              <li>
                You and your partner will continue taking turns as the Guesser and Hint-Giver until time is up and the game is over
              </li>
            </ul>
          </SectionContent>
        </Section>
        <Section>
          <SectionTitle>Ending a Turn</SectionTitle>
          <SectionContent>
            <li>
                The turn ends when:
              <ul>
                <li>Either the GOT IT! or PASS button is clicked</li>
                <li>The turn timer runs down to 0</li>
                <li>The game timer runs down to 0</li>
              </ul>
            </li>
            <li>
                The Hint-Giver should click the GOT IT! button when the Guesser correctly guesses the word
            </li>
            <li>
                Either player should click the PASS button when:
              <ol>
                <li>A player communicates in a language other than Spanish</li>
                <li>The Hint-Giver accidentally says the word</li>
                <li>You want to skip the word and move on to the next turn</li>
              </ol>
            </li>
          </SectionContent>
        </Section>
        <Section>
          <SectionTitle>Tips &amp; Tricks</SectionTitle>
          <SectionContent>
            <ul>
              <li>
                The point of the game is for you and your partner to work together to correctly guess as many words as possible, while communicating only in Spanish. So try to think quickly and speak clearly!
              </li>
              <li>
                Avoid the temptation to speak any language other than Spanish. The pressure and challenge of this game closely mimics the challenge you'll face when trying to speak Spanish with a native speaker in the real world -- stick with it, and your skills and confidence will improve greatly!
              </li>
              <li>
                If you get stuck on a word, you can hit the "PASS" button to end the turn and move on to the next
              </li>
            </ul>
          </SectionContent>
        </Section>
      </Modal>
    </ModalBackground>
  );
};

export default HowToPlayModal;
