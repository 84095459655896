import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getAnalytics } from "firebase/analytics";
import {
  connectDatabaseEmulator, // TEMP
  getDatabase,
} from "firebase/database";

import activebuildconfig from "configs/activebuildconfig.json";

// Initialize firebase app
export const firebaseApp = initializeApp({
  apiKey: activebuildconfig.FIREBASE_SETTING_API_KEY,
  appId: activebuildconfig.FIREBASE_SETTING_APP_ID,
  authDomain: activebuildconfig.FIREBASE_SETTING_AUTH_DOMAIN,
  databaseURL: activebuildconfig.FIREBASE_SETTING_RTD_URL,
  measurementId: activebuildconfig.FIREBASE_SETTING_MEASUREMENT_ID,
  messagingSenderId: activebuildconfig.FIREBASE_SETTING_MESSAGING_SENDER_ID,
  projectId: activebuildconfig.FIREBASE_SETTING_PROJECT_ID,
  storageBucket: activebuildconfig.FIREBASE_SETTING_STORAGE_BUCKET,
});

// Get auth element for use downstream
export const firebaseAuth = getAuth( firebaseApp );

// Get analytics for use downstream
export const firebaseAnalytics = getAnalytics( firebaseApp );

// Get Real Time Database for use downstream
export const firebaseRealTimeDatabase = getDatabase( firebaseApp );

// Connect to RTD emulator on local
// NOTE: This means that instead of just running the functions emulator locally during dev, you'll need to also run the Real Time Database emulator. The full command is: firebase emulators:start --only functions,database
// WARNING: This means that local development will allow all reads/writes (since our RTD rules are defined in STAGE instead of via code), so be sure to test and RTD read/write changes thoroughly on Stage, or comment this out and test while pointed to Stage
if ( location.hostname === "localhost" ) {
  connectDatabaseEmulator(firebaseRealTimeDatabase, "localhost", 9000);
}
