import {
  useEffect,
  useState,
} from "react";
import {
  useLocation,
  useNavigate,
} from "react-router-dom";
import {
  onValue,
  ref,
  set,
  update,
} from "firebase/database";

import Loading from "components/Loading";

import { OFFERS_PREFIX } from "const";

import { getCurrentUser } from "context/UserContext";

import { useServerTimestamp } from "hooks/useServerTimestamp";

import {
  createDailyRoom, 
  handleStartGameTimeout, 
} from "services/datastore";

import { firebaseRealTimeDatabase } from "services/firebase";
import { talkka } from "services/logging";

import FailedToJoinModal from "./FailedToJoinModal";

/**
 * Handles logic for starting the game, and once everything is done, passes
 * information along to the Play component
 */
const StartGame = () => {

  /*
   * HOOKS
   */

  const location = useLocation();

  const user = getCurrentUser();

  const navigate = useNavigate();

  const { getServerTimestamp } = useServerTimestamp();

  /*
   * STATE
   */

  // Boolean is true if local user is player1 in this game
  const [ localIsPlayer1, setLocalIsPlayer1 ] = useState( null );

  // Remember fetched room info from Daily
  const [ roomInfo, setRoomInfo ] = useState( null );

  // Modal to show if offer times out
  const [ showFailedToJoinModal, setShowFailedToJoinModal ] = useState( false );

  /*
   * USE EFFECT
   */

  // On load, confirm that we have the data we need to properly set up the game
  useEffect(() => {

    // Extract passed state and pathname from location object
    const {
      pathname,
      state: passedOfferInfo,
    } = location;

    // Check that we have valid info in the passed state, and that it matches the path
    if (
      !passedOfferInfo
      || !passedOfferInfo.player1
      || !passedOfferInfo.player2
      || ![ passedOfferInfo.player1.authId, passedOfferInfo.player2.authId ].includes( user.authId )
      // pathname should be in format /startGame/[player1 authId]
      || passedOfferInfo.player1.authId !== pathname.split("/")[2]
    ) {
      // TODO: Handle this more gracefully
      console.warn("INVALID STATE. Returning to lobby");
      navigate("/lobby");
    }

    // Check if local user is player1 or player2
    const isPlayer1 = user.authId === passedOfferInfo.player1.authId;
    setLocalIsPlayer1( isPlayer1 );

    // Super-quick way to ensure that nobody gets stuck waiting for RTD info: Set a timer for 20 seconds, and if we're still here when it finishes, push back to lobby
    // TODO: Make this better
    const timeoutId = setTimeout(() => {

      // fire-and-forget Cloud Function to send to stranded Player 2 the same SendGrid email (Play_Game_Offer_Abandoned) that stranded Player 1 gets
      handleStartGameTimeout({ 
        studentEmail: user.email,
        studentName: user.name,
      });

      // Record basic error info in a logEvent
      talkka.error(`User with email ${ user.email } failed to join game from offer created by user with email ${ passedOfferInfo.player1.email } at ${ new Date(passedOfferInfo.offerCreatedAtEpochMillis).toUTCString()}`);

      setShowFailedToJoinModal( true );
    }, 20 * 1000);

    // Player1 should dispatch async helper to fetch Daily room info and set in local state, as well as offerInfo in RTD
    if ( isPlayer1 ) {
      getAndSetDailyRoomInfo( passedOfferInfo.player1.authId );
      return function player1StartGameCleanup() {
        clearTimeout(timeoutId);
      };
    }
    else {
      // Function that sets up onValue listener returns its unsubscribe function
      const unsubscribeFromDailyRoomInfo = waitForDailyRoomInfo( passedOfferInfo.player1.authId );
      return function player2StartGameCleanup() {
        unsubscribeFromDailyRoomInfo();
        clearTimeout(timeoutId);
      };
    }

  }, []);

  // When we have Daily room info, use it to create a unique (and persistent across browser instances) location to save game info
  useEffect(() => {
    if ( !roomInfo ) {
      return;
    }

    // Unique path for RTD includes Daily room name and user authIds. These things combined should ensure both that RTD location is unique to this game, and that it is calculated the same by both user's browsers
    const path = `/games/${
      roomInfo.name
    }-${
      // offerInfo lives in location.state
      location.state.player1.authId
    }-${
      location.state.player2.authId
    }`;

    // Convenience ref to point to path
    const pathRef = ref(
      firebaseRealTimeDatabase,
      path,
    );

    // Both players will save their user info in RTD
    update(
      pathRef,
      { [`player${ localIsPlayer1 ? "1" : "2" }`]: user },
    );

    // Player1 will save roomInfo on game object in RTD
    if ( localIsPlayer1 ) {
      update(
        pathRef,
        {
          roomInfo,
          gameCreatedAtEpochMillis: getServerTimestamp(),
        },
      );
    }

    // Both players will listen for a change in RTD to know when the Daily room info is written
    const unsubscribeFromGameInfo = onValue(
      pathRef,
      ( snap ) => {

        const snapInfo = snap.val();

        // Extract room and player info
        const {
          player1,
          player2,
          roomInfo,
        } = snapInfo;

        // If we don't have all the info, don't do anything
        // NOTE: We're waiting for player1 and player2 to be written, so we know they have values when the Play route loads, but we're not doing anything else with them, here
        if (
          !player1
          || !player2
          || !roomInfo
        ) {
          return;
        }

        // Since player1 does most of the driving, they'll likely join a split second before player2. So to be safe, have player2 delete offerInfo at the moment of joining
        if ( !localIsPlayer1 ) {
          set(
            ref(
              firebaseRealTimeDatabase,
              `${OFFERS_PREFIX}/${player1.authId}`,
            ),
            null,
          );
        }

        // Once we have all info, pass it along to the play route
        navigate(
          `/play/${roomInfo.name}`,
          {
            replace: true,
            state: {
              gameInfoPath: path,
              localIsPlayer1: localIsPlayer1,
              roomInfo,
            },
          },
        );
      },
    );

    // Return unsubscribe function
    return unsubscribeFromGameInfo;

  }, [
    localIsPlayer1,
    roomInfo,
  ]);

  /*
   * HELPERS
   */

  // Player 1 will create the Daily room and set its info in local state, as well as RTD offer
  const getAndSetDailyRoomInfo = async ( player1Id ) => {
    // Create a Daily room and get its info from Cloud Functions
    // Passing epoch millis to keep browser & webpack from caching result of this call, which can result in joining an already-expired room
    const fetchedRoomInfo = await createDailyRoom({ epochMillis: getServerTimestamp() });

    // Save info in state - triggers useEffect that finalizes game prep for player1
    setRoomInfo( fetchedRoomInfo );

    // Save in RTD offer object - triggers useEffect that finalizes game prep for player2
    update(
      ref(
        firebaseRealTimeDatabase,
        `lobby/offers/${ player1Id }`,
      ),
      { roomInfo: fetchedRoomInfo },
    );
  };

  // Player 2 will watch the RTD offer for an update with the room name, so they know where to go next
  const waitForDailyRoomInfo = ( player1Id ) => {

    const unsubscribe = onValue(
      ref(
        firebaseRealTimeDatabase,
        `lobby/offers/${ player1Id }`,
      ),
      ( snap ) => {
         
        const offerData = snap.val();

        if ( !offerData ) {
          return;
        }

        const { roomInfo } = offerData;

        // If we get roomInfo, save it locally and kill this listener
        if ( roomInfo ) {
          setRoomInfo( roomInfo );
        }
      },
    );

    // Return unsubscribe function to useEffect for use in cleanup
    return unsubscribe;
  };

  /*
   * RENDER
   */

  // As long as we're on this route, we're loading things for the game!
  return (
    <>
      <Loading />
      <FailedToJoinModal isVisible={ showFailedToJoinModal } />
    </>
  );
};

export default StartGame;
