import { Fragment } from "react";
import styled from "styled-components/macro";

import Button from "components/Button";

const InviteTextContainer = styled.div`
  background: #ddd;
  border-radius: ${({ theme }) => theme.borderRadius};
  box-shadow: ${({ theme }) => theme.boxShadow};
  margin: 0.5rem auto;
  padding: 0.5rem;
  text-align: left;
  width: 90%;
`;

/**
 * Display shareable snippet to encourage others to join, and button to
 * re-create an offer
 *
 * @param { Function } tryAgain - function to delete and recreate user offer
 */
const ShareWithFriends = ({ tryAgain }) => {

  const emojiString =
    String.fromCodePoint(0x1F5E3)
    + String.fromCodePoint(0x1F389)
    + String.fromCodePoint(0x1F393);

  const inviteText = [
    "Play Talkkaround and speak Spanish with me! " + emojiString,
    "https://play.beepboop.us",
    "#langtwt #learnSpanish #talkkaround #beepboop",
  ];

  return (
    <div>
      <p>
        I'm sorry, I can't find a partner for you right now.
      </p>
      <Button
        onClick={ tryAgain }
        text="Try Again"
      />
      <p>
        Why not invite a friend to play with you?
      </p>
      <InviteTextContainer>
        { inviteText.map((line, index) => (
          <Fragment key={index}>
            { line }
            <br />
          </Fragment>
        ))}
      </InviteTextContainer>
      <Button
        onClick={ () => navigator.clipboard.writeText(inviteText.join("\n")) }
        secondary
        buttonStyle="background: rebeccapurple;"
        text="Share (copy to clipboard)"
      />
    </div>

  );
};

export default ShareWithFriends;
