import {
  useEffect,
  useState,
} from "react";

import styled from "styled-components/macro";

import talkkaGlasses from "assets/talkka_glasses.png";
import talkkaMegaphone from "assets/talkka_megaphone.png";

import { useGameContext } from "context/GameContext";

const HinterView = styled.dl`
  opacity: ${ props => props.localIsGuesser ? 0 : 1 };
  text-align: center;
  transition: opacity 0.5s ease;
  width: 50%;
  height: 100%;
`;

const GuesserView = styled.div`
  opacity: ${ props => props.localIsGuesser ? 1 : 0 };
  text-align: center;
  transition: opacity 0.5s ease;
  width: 50%;
`;

const TalkkaImage = styled.img`
  margin: 1rem auto;
  width: 5rem;
`;

const TargetWord = styled.dt`
  font-size: 1.25rem;
  font-weight: 600;
`;

const TranslatedWord = styled.dd`
  cursor: pointer;
  font-size: 0.75rem;
  margin: 0;
`;

/**
 * Displays the hinter- or guesser-specific view for a turn
 *
 * @returns
 */
const TurnView = () => {

  const {
    currentTurnInfo,
    localIsGuesser,
  } = useGameContext();

  // Show translated word when user clicks
  const [ showTranslation, setShowTranslation ] = useState( false );

  // Remember info in local state, so we can control when it displays
  const [ word, setWord ] = useState( null );
  const [ showGuesserView, setShowGuesserView ] = useState( localIsGuesser );

  // Set separate 1.5-second delays to update word and view type -- prevents hint-giver info from being visible to the new guesser as the views change
  useEffect(() => {
    const wordTimeout = setTimeout(
      () => setWord( currentTurnInfo?.word ),
      1500,
    );
    const cleanUpWordTimeout = () => clearTimeout( wordTimeout );
    return cleanUpWordTimeout;
  }, [ currentTurnInfo ]);

  useEffect(() => {
    const viewTimeout = setTimeout(
      () => setShowGuesserView( localIsGuesser ),
      1500,
    );
    const cleanUpViewTimeout = () => clearTimeout( viewTimeout );
    return cleanUpViewTimeout;
  }, [ localIsGuesser ]);

  return showGuesserView
    ? (
      <GuesserView localIsGuesser={ localIsGuesser }>
        <TalkkaImage src={ talkkaGlasses } />
        <p>
          Guess the word!
        </p>
      </GuesserView>
    )
    : (
      <HinterView localIsGuesser={ localIsGuesser }>
        <TalkkaImage src={ talkkaMegaphone } />
        <TargetWord>
          { localIsGuesser ? "Don't cheat!" : word?.es }
        </TargetWord>
        <TranslatedWord onClick={ () => setShowTranslation( prev => !prev )}>
          { localIsGuesser
            ? "You won't learn that way"
            : showTranslation
              ? word?.en
              : "Show translation" }
        </TranslatedWord>
      </HinterView>
    );
};

export default TurnView;
