import styled from "styled-components/macro";

const Input = styled.input`
  background: ${props => props.readOnly ? "#808080" : "#ffffff"}d9;
  border: 1px solid ${props => props.hasError ? "red" : "black"};
  border-radius: ${ props => props.theme.borderRadius };
  padding: 0.5rem;
`;

/**
 * Renders one input element of any arbitrary type with custom styling.
 * Arguments other than those named in this comment are added to the <input>
 * element when it is rendered.
 *
 * @param { boolean } hasError - true if error styling should be applied
 * @param { boolean } readOnly - true if field should not be editable, and
 * read-only styling should be applied 
 * @param { string } label - Text to display before the component
 * @returns
 */
const StyledInput = ({
  hasError,
  readOnly,
  label,
  ...genericProps
}) => (
  <label>
    { `${label}: ` }
    <Input
      hasError={ hasError }
      readOnly={ readOnly }
      {...genericProps}
    />
  </label>
);

export default StyledInput;
