import {
  useEffect,
  useRef,
  useState,
} from "react";
import {
  useLocation,
  useNavigate,
} from "react-router-dom";
import { signOut } from "firebase/auth";
import styled from "styled-components/macro";

import { getCurrentUser } from "context/UserContext";

import { firebaseAuth } from "services/firebase";

const MenuContainer = styled.section`
  /* POSITION */
  position: absolute; // Against HeaderContainer
  right: 0.5rem;
  top: 50%;
  transform: translateY(-50%);

  display: inline-block;

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    top: unset;
    bottom: 0;
    transform: none;
  }
`;

const MenuButton = styled.button`
  background: ${ props => props.theme.turquoise };
  border: 1px solid white;  
  border-radius: ${ props => props.theme.borderRadius };
  box-shadow: ${ props => props.theme.boxShadow };
  color: white;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 500;
  padding: 0.25rem 0.75rem;
  text-shadow: ${ props => props.theme.textShadow };
`;

const InnerMenu = styled.menu`
  /* POSITION */
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;

  background: #eee;
  border: 1px solid #ddd;
  border-radius: ${ props => props.theme.borderRadius };
  box-shadow: ${ props => props.theme.boxShadow };
  color: white;
  list-style: none;
  margin: 0;
  opacity: ${ props => props.isOpen ? 1 : 0 };
  overflow: hidden;
  padding: 0;
  z-index: 1;

  // Animate open/close
  max-height: ${ props => props.isOpen ? `${props.mhVal}px` : 0 };
  transition: max-height 0.5s ease, opacity 0.5s ease;
`;

const InnerMenuPadding = styled.div`
  padding: 0.5rem;
`;

const MenuItem = styled.button`
  background: transparent;
  border: 0;
  box-shadow: none;
  color: ${ props => props.theme.turquoise };
  cursor: pointer;
  font-size: 1.1rem;
  font-weight: 500;
  margin: 0.25rem 0;
  text-align: left;
  text-shadow: ${ props => props.theme.textShadow };
`;

/**
 * Render a dropdown navigation menu in the persistent header. Menu items
 * dynamically change based on location and login state
 */
const Menu = ({ setShowHowToPlayModal }) => {

  /*
   * CONTEXT & HOOKS
   */

  // Get user info and state-setter from within context
  const user = getCurrentUser();

  const navigate = useNavigate();

  const location = useLocation();

  const menuRef = useRef();

  /*
   * STATE
   */

  // Toggle menu open/close
  const [ isOpen, setIsOpen ] = useState( false );

  // True if user is logged in, either via Speak App credentials, or via creating a local 'anonymous' user
  const [ userIsLoggedIn, setUserIsLoggedIn ] = useState( false );

  /*
   * USE EFFECT
   */

  // When user info updates, keep a concept of if the user is logged-in (via Speak App creds), or anonymous
  useEffect(() => {

    // If we have a user object, the user is logged in
    setUserIsLoggedIn( () => !!user );

  }, [ user ]);

  /*
   * HELPERS
   */

  // Log the user out and return them to the start of the flow
  const logOutUser = async () => {

    // Use Firebase logout function. Removes user in auth, and downstream effects remove currentUser in context and local storage
    await signOut( firebaseAuth );

    // Go home
    navigate("/");
  };

  return (
    <MenuContainer>
      <MenuButton onClick={ () => setIsOpen( prev => !prev ) }>
        { isOpen ? "CLOSE" : "OPEN" } MENU
      </MenuButton>
      <InnerMenu
        isOpen={ isOpen }
        onClick={ () => setIsOpen( prev => !prev ) }
        ref={ menuRef }
        // scrollHeight is computed height of the element's content, without padding or border
        mhVal={ menuRef.current?.scrollHeight }
      >
        <InnerMenuPadding>
          {// If we're in a game (at the /play/ route), show the Exit game link
            location.pathname.startsWith("/play/")
            && (
              <li>
                <MenuItem
                  onClick={ () => navigate("/lobby") }
                >
                  Exit Game
                </MenuItem>
              </li>
            )
          }
          <li>
            <MenuItem
              onClick={() => setShowHowToPlayModal( true ) }
            >
              How To Play
            </MenuItem>
          </li>
          <li>
            <MenuItem
              onClick={ () => {
                window.open("https://speak.beepboop.us", "_blank");
              }}
            >
              Go to Beepboop
            </MenuItem>
          </li>
          {// Show logout button only if user is already logged in!
            userIsLoggedIn
            && (
              <li>
                <MenuItem
                  onClick={ logOutUser }
                >
                  Log out
                </MenuItem>
              </li>
            )
          }
        </InnerMenuPadding>
      </InnerMenu>
    </MenuContainer>
  );
};

export default Menu;
