import {
  useEffect,
  useRef,
  useState,
} from "react";

/**
 * Hook to get a countdown timer for the indicated number of seconds.
 * Hook doesn't manage display, just counting down the value
 *
 * @param { number } secs - for timer to last
 */
export const useTimer = ( secs ) => {

  /*
   * STATE (and state-like things)
   */

  // Array of timeout ids for this timer - used to clear timer when needed
  const TIMEOUT_IDS = useRef( [] );

  // Keep track of current timer value
  const [ secsRemaining, setSecsRemaining ] = useState( null );

  /*
   * USE EFFECT
   */

  // On unmount, clear timers
  useEffect(() => {
    return clear;
  }, []);

  /*
   * MAIN FUNCTIONS
   */

  // Clear all timeouts and unset seconds remaining
  const clear = () => {

    // Clear timeouts
    TIMEOUT_IDS.current.forEach(id => clearTimeout(id) );

    // Reset timeout Ids to an empty array
    TIMEOUT_IDS.current = [];

    // Clear seconds remaining
    setSecsRemaining( null );
  };

  // Set up timeouts for timer to tick down
  const start = () => {

    // Clear any timeouts from previous start
    clear();

    // Set up intervals for new timer iteration
    for ( let i = 0; i <= secs; i++ ) {
      TIMEOUT_IDS.current.push(
        setTimeout( () => setSecsRemaining( secs - i ), i * 1000 ),
      );
    }

    // One second after timer is over, set value to null (indicates timer is done)
    TIMEOUT_IDS.current.push(
      setTimeout( () => setSecsRemaining( null ), (secs + 1) * 1000 ),
    );
  };

  return {
    clear,
    secsRemaining,
    start,
  };
};
