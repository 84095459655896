import styled from "styled-components/macro";

import talkkaCrying from "assets/talkka_crying.gif";

import Loading from "components/Loading";

const ConnectionWarningContainer = styled.div`
  /* DISPLAY */
  display: flex;
  align-items: center;
  justify-content: center;

  /* POSITION */
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  background: linear-gradient(rgba(0,0,0,0.5),rgba(0,0,0,0.5));
  z-index: 15; /* To be above all else, including FailedToJoinModal */
`;

const ConnectionWarningModal = styled.section`
  background: white;
  border-radius: ${ ({ theme }) => theme.borderRadius };
  box-shadow: ${ ({ theme }) => theme.boxShadow };
  box-sizing: border-box;
  padding: 1rem;
  position: relative;
  width: 67vw;

  p {
    font-size: 1.1rem;
  }

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    width: 90%;
  }
`;

const ConnectionWarningHeading = styled.div`
  /* DISPLAY */
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ConnectionWarningImage = styled.img`
  margin: 0 1rem;
  width: 5rem;
`;

/**
 * Render the modal that appears when a student's socket connection to our Real
 * Time Database instance drops
 * 
 * @param { Boolean } isShowing - Managed in useRealTimeDatabase, true if this
 * component should be visible
 * @returns 
 */
const ConnectionWarning = () => (
  <ConnectionWarningContainer>
    <ConnectionWarningModal>
      <ConnectionWarningHeading>
        <h1>¡El internet se fue!</h1>
        <ConnectionWarningImage src={ talkkaCrying } />
      </ConnectionWarningHeading>
      <p>
        Your internet connection is currently too unstable for a great Talkkaround experience. We are attempting to reconnect you, but you may need to find a better internet connection.
      </p>
      <Loading text="" />
    </ConnectionWarningModal>
  </ConnectionWarningContainer>
);

export default ConnectionWarning;
