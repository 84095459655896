import {
  useEffect,
  useState,
} from "react";

import {
  useLocation, 
  useNavigate, 
  useSearchParams,
} from "react-router-dom";

import { signInAnonymously } from "firebase/auth";
import styled from "styled-components/macro";

import talkkaOver from "assets/talkkaOver.png";

import StyledInput from "components/StyledInput";
import StyledSubmit from "components/StyledSubmit";

import { firebaseAuth } from "services/firebase";


const LoginContainer = styled.main`
  background: white;
  border-radius: ${ props => props.theme.borderRadius };
  box-shadow: ${ props => props.theme.boxShadow };
  margin: 1rem auto;
  max-width: 680px;
  min-width: 340px;
  padding: 1rem;
`;

const LoginHeading = styled.h1`
  margin-top: 0.5rem;
  text-align: center;
`;

const LoginFormContainer = styled.div`
  margin-top: 7.5rem;
  position: relative;
  text-align: center;
`;

const LoginForm = styled.form`
  background: ${ props => props.theme.turquoise }ee;
  border-radius: ${ props => props.theme.borderRadius };
  box-shadow: ${ props => props.theme.boxShadow };
  color: white;
  display: inline-block;
  font-size: 1.25rem;
  padding: 0.75rem 1rem;
  text-align: center;

  > * {
    display: block;
    font-size: 1.25rem;
    margin: 0.5rem auto;
  }
`;

const TalkkaContainer = styled.div`
  /* POSITION */
  position: absolute; // Against LoginFormContainer
  left: 50%;
  top: -4.5rem;
  transform: translateX(-50%);

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    left: 25%;
  }
`;

const TalkkaImage = styled.img`
  width: 5rem;
`;

const SpeechBubble = styled.p`
  /* POSITION */
  position: absolute; // Against TalkkaContainer
  top: -2.5rem;
  right: 0;
  transform: translateX(calc(100% + 2rem));

  background: ${({ theme }) => theme.orange};
  border: 5px solid #666;
  border-radius: ${({ theme }) => theme.borderRadius};
  box-shadow: ${({ theme }) => theme.boxShadow};
  color: #fff;
  display: block;
  font-weight: 500;
  margin: 0;
  padding: 0.5rem;
  text-shadow: ${({ theme }) => theme.textShadow};
  width: 10rem;

  &:before {
    /* POSITION */
    position: absolute;
    bottom: 15px;
    left: -30px;
  
    border-color: transparent #666;
    border-style: solid;
    border-width: 15px 30px 15px 0;
    content: "";
    display: block;
    width: 0;
  }

  &:after {
    /* POSITION */
    position: absolute;
    bottom: 21px;
    left: -21px;

    border-color: transparent ${({ theme }) => theme.orange};
    border-style: solid;
    border-width: 9px 21px 9px 0;
    content: "";
    display: block;
    width: 0;
  }
`;

/**
 * If the user entered an email not associated with a Speak App account in the
 * /login route, creates a new 'anonymous' user for this session
 *
 * @returns 
 */
const CreateAnonymousUser = () => {

  /*
   * HOOKS
   */

  const location = useLocation();

  // Get search params. Hook returns value and setter (like React useState), but we just need the value
  const [ searchParams ] = useSearchParams();
  
  const navigate = useNavigate();

  /*
   * STATE
   */

  // Loading flag
  const [ loading, setLoading ] = useState( false );

  // Info for creation of new anonymous user in context
  const [ email, setEmail ] = useState( "" );
  const [ name, setName ] = useState( "" );

  /*
   * USE EFFECT
   */

  // On load, check if email & name were passed in from previous route. If so, create an anonymous user with those credentials
  useEffect(() => {

    // Grab email & name (and preferred bundle, if any) from passed state or from querystring
    const passedEmail = location?.state?.email ? location.state.email : searchParams.get("email");
    const passedName = location?.state?.name ? location.state.name : searchParams.get("name");
    const passedBundle = location?.state?.bundle ? location.state.bundle : searchParams.get("bundle");
    
    // email must be in the format __@__.__
    const emailRGEX = /^.+@.+\..+$/;

    if ( passedEmail && passedName && emailRGEX.test(passedEmail.trim()) ) {
      signInAnonymousUser({
        email: passedEmail.trim(),
        name: passedName,
        bundle: passedBundle,
      });
    }

  }, []);

  /*
   * HELPERS
   */

  const handleSubmitNewUser = ( event ) => {
    // Prevent reload on form submission
    event.preventDefault();

    signInAnonymousUser({
      email,
      name,
    });
  };

  const signInAnonymousUser = async ({
    bundle,
    email,
    name,
  }) => {
    // Toggle loading flag
    setLoading( true );

    // Set user-input values in localStorage - used in UserContext to attach the info to exposed currentUser value
    localStorage.setItem("anonUser", JSON.stringify({
      preferredWordBundle: bundle,
      email,
      name,
    }));

    // Use firebase function to 'sign in' anonymously - ensures that user has permissions to read/write in Real Time Database
    await signInAnonymously( firebaseAuth );

    // TODO: determine a more elegant way of grabbing new email, name, and especially preferredWordBundle from querystring when navigating from Speak App
    // since this is no longer wrapped in <PushUserToLobby>, need to navigate to lobby after logging in anonymously
    navigate("/lobby");
  };

  return (
    <LoginContainer>
      <LoginHeading>
        Have Fun. Speak Spanish.
      </LoginHeading>
      <p>
        Welcome to Talkkaround (beta), where you build practical Spanish speaking and comprehension skills by playing a fun, fast-paced word game with other language learners!
      </p>
      <LoginFormContainer>
        <LoginForm onSubmit={ handleSubmitNewUser }>
          <StyledInput
            autoFocus
            label="Name"
            onChange={ (e) => setName(e.target.value) }
            readOnly={ loading }
            required
            type="text"
            value={ name }
          />
          <StyledInput
            label="Email"
            onChange={ (e) => setEmail(e.target.value) }
            readOnly={ loading }
            required
            type="email"
            value={ email }
          />
          <StyledSubmit />
        </LoginForm>
        <TalkkaContainer>
          <TalkkaImage src={ talkkaOver } />
          <SpeechBubble>
            Hi, I'm Talkka! Enter your name and email to play my fun new game, Talkkaround!
          </SpeechBubble>
        </TalkkaContainer>
      </LoginFormContainer>
    </LoginContainer>
  );
};

export default CreateAnonymousUser;
