import {
  useContext,
  useEffect,
} from "react";
import styled from "styled-components/macro";

import talkkaHead from "assets/talkka_pendulum.gif";

import { UserContext } from "context/UserContext";

import { useGames } from "hooks/useGames";

import versionJSON from "version/version";
const { version } = versionJSON;

import ClickToPlay from "./ClickToPlay";


const LobbyContainer = styled.main`
  /* DISPLAY */
  display: grid;
  grid-template-columns: 1fr 2fr;
  column-gap: 1rem;

  margin: 0 auto;
  width: 980px;
  max-width: 95vw;

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    display: block;
  }
`;

const RuleList = styled.ol`
  /* DISPLAY */
  display: flex;
  flex-flow: row nowrap;
  align-items: stretch;
  justify-content: center;

  list-style: none;
  margin: 2rem 0 0;
  padding: 0;

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    flex-flow: column nowrap;
    margin: 0;
  }
`;

const Rule = styled.li`
  background: ${({ theme }) => theme.turquoise};
  border-radius: ${({ theme }) => theme.borderRadius};
  box-shadow: ${({ theme }) => theme.boxShadow};
  box-sizing: border-box;
  color: white;
  counter-increment: rulesCounter;
  font-weight: 500;
  margin: 0 0.5rem;
  padding: 1rem;
  position: relative;
  text-shadow: ${({ theme }) => theme.textShadow};
  width: 25%;

  &::before {
    --ruleNumTextSize: 1.5rem;

    /* POSITION */
    position: absolute;
    top: calc(var(--ruleNumTextSize) * -0.75);
    left: calc(var(--ruleNumTextSize) * -0.5);

    content: counter(rulesCounter);
    background: ${({ theme }) => theme.orange};
    border-radius: 50%;
    height: calc(var(--ruleNumTextSize) * 1.5);
    font-size: var(--ruleNumTextSize);
    line-height: calc(var(--ruleNumTextSize) * 1.5);
    text-align: center;
    width: calc(var(--ruleNumTextSize) * 1.5);
  }

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    margin: 1rem auto;
    width: 95%;
  }
`;

const StartPlayingSection = styled.section`
  background: ${ ({ theme }) => theme.turquoise };
  border-radius: ${ ({ theme }) => theme.borderRadius };
  box-shadow: ${ ({ theme }) => theme.boxShadow };
  color: white;
  height: fit-content;
  padding: 0 1rem 1rem;
  text-align: center;

  label {
    display: inline-block;
    font-size: 0.9em;
    font-style: italic;
    margin-top: 0.5rem;
  }

  h2 {
    color: white;
    margin: 0;
  }
`;

const TalkkaGif = styled.img`
  width: 50%;

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    width: 33%;
  }
`;

const PlayerCount = styled.aside`
  background: ${({ theme }) => theme.purple};
  border-radius: ${ ({ theme }) => theme.borderRadius };
  box-shadow: ${ ({ theme }) => theme.boxShadow };
  color: white;
  padding: 0.5rem;
  text-align: center;
`;

/**
 * New Lobby page - shows basic How To Play info, and offers one-click joining
 * of a game (joining an existing offer or, if none exists, creating a new one)
 */
const Lobby = () => {

  /*
   * CONTEXT & HOOKS
   */

  const { setCurrentUser } = useContext( UserContext );

  const { gameCount } = useGames();

  /*
   * USE EFFECT
   */

  // On load, make sure we have a valid app version
  useEffect(() => {

    // TODO: push this higher in the call stack? Maybe onto RequireUser
    // ensure version is always up to date when stamping player1 & player 2 in offer & game
    setCurrentUser((prev) => ({
      ...prev,
      appVersion: version,
    }));
  }, []);

  return (
    <LobbyContainer>
      <StartPlayingSection>
        <div>
          <TalkkaGif src={ talkkaHead } />
          <h2>Hi, I'm Talkka!</h2>
        </div>
        <p>Welcome to Talkkaround! I'm Talkka, your host for this game.</p>
        <ClickToPlay />
      </StartPlayingSection>
      <article>
        {// If any games are being played, show how many!
          !!gameCount
          && (
            <PlayerCount>
              { gameCount * 2 } people are playing Talkkaround right now. Click 'Play Now' to join the fun!
            </PlayerCount>
          )
        }
        <h2>How To Play</h2>
        <p>Talkkaround (beta) is a cooperative language-learning game, in which you and a partner work together to practice your speaking skills and expand your vocabulary.</p>
        <p>It's a word-guessing game, like Taboo or charades. You play the game with a partner: The Hint-giver sees a word on the screen, and gives the Guesser clues to try to help them correctly guess the word. You'll switch roles after every word.</p>
        <p>There are some rules to this game:</p>
        <RuleList>
          <Rule>Only speak in Spanish</Rule>
          <Rule>Don't say the word (or any variation thereof)</Rule>
          <Rule>Hint-givers: Press the "Correct" button once the Guesser guesses the word</Rule>
          <Rule>If you get stuck, hit the "Pass" button to go to the next word</Rule>
        </RuleList>
      </article>
    </LobbyContainer>
  );
};

export default Lobby;
