import { useNavigate } from "react-router-dom";
import styled from "styled-components/macro";

import Button from "components/Button";

import { useOffers } from "hooks/useOffers";

import SocialShare from "./SocialShare";

const RecapContainer = styled.article`
  background: white;
  border-radius: ${({ theme }) => theme.borderRadius};
  box-shadow: ${({ theme }) => theme.boxShadow};
  padding: 1rem;
`;

const ActionSection = styled.section`
  display: grid;
  column-gap: 2rem;
  grid-template-columns: 1fr 1fr 1fr;

  margin: 2rem 1rem 1rem;

  > * {
    border: 1px solid silver;
    border-radius: ${({ theme }) => theme.borderRadius};
    box-shadow: ${({ theme }) => theme.boxShadow};
    padding: 1rem;
  }

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    display: block;

    > * {
      margin: 1rem auto;
    }
  }
`;

const EmphasizedParagraph = styled.p`
  color: ${({ theme }) => theme.purple};
  font-weight: 500;
  text-shadow: ${({ theme }) => theme.textShadow};
`;

/**
 * Once the game is over, show recap info and next steps
 */
const GameRecap = () => {

  /*
   * HOOKS
   */

  const navigate = useNavigate();

  const { offers } = useOffers();

  /*
   * RENDER
   */

  return (
    <RecapContainer>
      <section>
        <SocialShare />
      </section>
      <ActionSection>
        <div>
          <Button
            onClick={ () => navigate("/lobby") }
            text="Play Again"
            buttonStyle="display:block;margin:auto;"
            secondary
          />
          {// If there's an offer right now, notify!
            !!offers.length
            && (
              <EmphasizedParagraph>
                Someone is waiting for a partner! Click the button above to play right now
              </EmphasizedParagraph>
            )
          }
          <p>The more you practice speaking, the better you'll be able to communicate in real life!</p>
        </div>
        <div>
          <Button
            onClick={ () => window.location.href = "https://speak.beepboop.us" }
            text="Take Spanish Class"
            buttonStyle="display:block;margin:0 auto 1rem;"
            secondary
          />
          <p>Want to go more in-depth? Take a free, live 25-minute Spanish class with Beepboop!</p>
        </div>
        <div>
          <Button
            onClick={ () => window.location.href = "mailto:amigos@beepboop.us?subject=Talkkaround Feedback" }
            text="Send Feedback"
            buttonStyle="display:block;margin:0 auto 1rem;"
            secondary
          />
          <p>Did you have a great time? Did you encounter tech issues or have problems with hearing your partner? <a href="mailto:amigos@beepboop.us?subject=Talkkaround Feedback">Let us know!</a></p>
        </div>
      </ActionSection>
    </RecapContainer>
  );
};

export default GameRecap;
