import {
  useEffect,
  useState,
} from "react";
import styled from "styled-components/macro";

import { useTimer } from "hooks/useTimer";

import CountdownDisplay from "./CountdownDisplay";
import ShareWithFriends from "./ShareWithFriends";

const FindPartnerCountdownContainer = styled.section`
  background: white;
  border-radius: ${ ({ theme }) => theme.borderRadius };
  box-shadow: ${ ({ theme }) => theme.boxShadow };
  color: black;
  padding: 1rem;
  text-align: center;

  p {
    margin: 0.25rem 0;
  }
`;

/**
 * Displays info to encourage user to wait for a partner to join. After
 * countdowns have elapsed, displays sharing component
 *
 * Note that createOffer and deleteOffer are passed in from parent component to
 * avoid two instances of the useOffers hook being present on the page at once
 *
 * @param { Function } createOffer - function to create a new user offer
 * @param { Function } deleteOffer - function to delete the user offer
 */
const FindPartnerCountdown = ({
  createOffer,
  deleteOffer,
}) => {

  // Timers for visible and invisible countdowns
  const visibleCountdown = useTimer( 25 + (Math.random() < 0.5 ? 1 : 0) );
  const invisibleCountdown = useTimer( 15 + (Math.random() < 0.5 ? 1 : 0) );

  // Boolean that flips to true once both countdowns have finished
  const [ countdownsComplete, setCountdownsComplete ] = useState( false );

  // On component mount, start visible timer
  useEffect(() => {
    visibleCountdown.start();
    // Timers self-clear automatically on unmount, so no need to do that here
  }, []);

  // As soon as visible countdown finishes, start the invisible timer
  useEffect(() => {
    if ( visibleCountdown.secsRemaining === 0 ) {
      invisibleCountdown.start();
    }
  }, [ visibleCountdown.secsRemaining ] );

  // When invisible countdown finishes, remove the offer and notify the student
  useEffect(() => {
    if ( invisibleCountdown.secsRemaining === 0 ) {
      setCountdownsComplete( true );
      deleteOffer();
    }
  }, [ invisibleCountdown.secsRemaining ]);

  // When user clicks Try Again button, restart timers
  const tryAgain = async () => {
    await createOffer();
    setCountdownsComplete( false );
    visibleCountdown.start();
  };

  return (
    <FindPartnerCountdownContainer>
      {countdownsComplete
        ? <ShareWithFriends tryAgain={ tryAgain } />
        : (
          <CountdownDisplay
            visibleCountdownSecs={ visibleCountdown.secsRemaining }
          />
        )
      }
    </FindPartnerCountdownContainer>
  );
};

export default FindPartnerCountdown;
