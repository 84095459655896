// This is CRA's built-in way to report performance metrics for the app
// See https://create-react-app.dev/docs/measuring-performance/ for usage

const reportWebVitals = onPerfEntry => {
  if (onPerfEntry && onPerfEntry instanceof Function) {
    import("web-vitals").then(({
      getCLS,
      getFID,
      getFCP,
      getLCP,
      getTTFB,
    }) => {
      getCLS(onPerfEntry);
      getFID(onPerfEntry);
      getFCP(onPerfEntry);
      getLCP(onPerfEntry);
      getTTFB(onPerfEntry);
    });
  }
};

export default reportWebVitals;
