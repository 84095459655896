import {
  useContext,
  useEffect,
} from "react";
import {
  useLocation,
  useNavigate,
} from "react-router-dom";
import styled from "styled-components/macro";

import { useCallContext } from "context/CallContext";
import { useGameContext } from "context/GameContext";
import { UserContext } from "context/UserContext";

import Controls from "./Controls";
import ErrorMessage from "./ErrorMessage";
import Instructions from "./Instructions";
import TurnArea from "./TurnArea";
import GameInfo from "./GameInfo";
import GameRecap from "./GameRecap";

const PlayContainer = styled.main`
  /* DISPLAY */
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
  justify-content: center;

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    flex-flow: column nowrap;
  }
`;

const PlayColumn = styled.div`
  margin: 0.5rem;

  &:nth-child(2) {
    box-shadow: ${({theme}) => theme.boxShadow};
  }

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    margin: 0.5rem auto;
  }
`;

/**
 * Parent component for in-game experience. Renders all controls and visuals
 * for the game to actually happen
 */
const Play = ({
  setLockHowToPlayModal,
  setShowHowToPlayModal,
}) => {

  /*
   * HOOKS & CONTEXT
   */

  const location = useLocation();

  const navigate = useNavigate();

  const { initializeCall } = useCallContext();

  const {
    gameIsFinished,
    initializeGame,
    remotePlayerIsPresent,
    startGame,
  } = useGameContext();

  const { setCurrentUser } = useContext( UserContext );

  /*
   * USE EFFECT
   */

  // On unmount, unset preferred wordBundle (prevents same bundle from being set every time)
  useEffect(() => {
    return function unsetPreferredWordBundle() {
      setCurrentUser((prev) => ({
        ...prev,
        preferredWordBundle: null,
      }));
    };
  }, []);

  // On load, join the Daily call
  useEffect(() => {

    // Show the How To Play Modal, and don't let it close
    setShowHowToPlayModal( true );
    setLockHowToPlayModal( true );

    // If we didn't have state passed in, then we can't join the game
    if ( !location.state ) {
      return navigate("/lobby", { replace: true });
    }

    // Extract info from passed state
    const {
      gameInfoPath,
      localIsPlayer1,
      roomInfo,
    } = location.state;

    // If we don't have the needed info from location, go back to the lobby
    if (
      !gameInfoPath
      || !roomInfo
      || typeof localIsPlayer1 !== "boolean"
    ) {
      return navigate("/lobby", { replace: true });
    }

    asyncStartPlaying({
      gameInfoPath,
      localIsPlayer1,
      roomInfo,
    });

  }, [ location ]);

  // Release modal and start game when other player is here
  useEffect(() => {
    if ( remotePlayerIsPresent ) {
      setShowHowToPlayModal( false );
      setLockHowToPlayModal( false );
      startGame();
    }
  }, [ remotePlayerIsPresent ]);

  /*
   * HELPER FUNCTIONS
   */

  // Helper to start the game
  const asyncStartPlaying = async ({
    gameInfoPath,
    localIsPlayer1,
    roomInfo,
  }) => {

    // If we're past room expiration time, we can be confident that the game is over, and the user reached this page by opening an old tab
    if ( roomInfo?.config?.exp * 1000 < Date.now() ) {
      // Push user back to lobby
      return navigate("/lobby", { replace: true });
    }

    // Try to set up the game - redirects to lobby if it detects that the user is trying to join an old game that got past the Daily roomInfo.config.exp check
    await initializeGame({
      gameInfoPath,
      localIsPlayer1,
    });

    // Set up call context and join Daily call
    initializeCall( roomInfo );
  };

  /*
   * RENDER
   */

  return (
    <PlayContainer>
      <PlayColumn>
        <Controls />
      </PlayColumn>
      {// When game is over, just display recap
        gameIsFinished
          ? (
            <GameRecap />
          )
          : (
            <>
              <PlayColumn>
                <Instructions />
                <TurnArea />
              </PlayColumn>
              <PlayColumn>
                <GameInfo />
              </PlayColumn>
            </>
          )
      }
      <ErrorMessage />
    </PlayContainer>
  );
};

export default Play;
