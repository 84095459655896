import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import styled, {
  createGlobalStyle,
  ThemeProvider,
} from "styled-components/macro";

import ConnectionMonitor from "components/ConnectionMonitor";
import PersistentFooter from "components/PersistentFooter";
import PersistentHeader from "components/PersistentHeader";
import HowToPlayModal from "components/HowToPlayModal";

const GlobalStyles = createGlobalStyle`
  body {
    font-family: Fredoka, sans-serif;
    margin: 0;
    padding: 0;
    position: relative;
  }
  button, input {
    font-family: Fredoka, sans-serif;
  }
  h1, h2, h3, h4, h5, h6 {
    color: ${ props => props.theme.orange };
    font-family: Fredoka One, sans-serif;
    font-weight: 400;
    text-shadow: ${ props => props.theme.textShadow };
  }
`;

const MainArea = styled.div`
  padding: 0 0.55rem;
`;

const BackgroundContainer = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;

  /* Generated with magicpattern.design, based on color of Speak App login background */
  opacity: 0.1;
  background-image:  linear-gradient(30deg, #00bbc9 12%, transparent 12.5%, transparent 87%, #00bbc9 87.5%, #00bbc9), linear-gradient(150deg, #00bbc9 12%, transparent 12.5%, transparent 87%, #00bbc9 87.5%, #00bbc9), linear-gradient(30deg, #00bbc9 12%, transparent 12.5%, transparent 87%, #00bbc9 87.5%, #00bbc9), linear-gradient(150deg, #00bbc9 12%, transparent 12.5%, transparent 87%, #00bbc9 87.5%, #00bbc9), linear-gradient(60deg, #00bbc977 25%, transparent 25.5%, transparent 75%, #00bbc977 75%, #00bbc977), linear-gradient(60deg, #00bbc977 25%, transparent 25.5%, transparent 75%, #00bbc977 75%, #00bbc977);
  background-size: 80px 140px;
  background-position: 0 0, 0 0, 40px 70px, 40px 70px, 0 0, 40px 70px;
`;

// White overlay behind background
const DoubleBackground = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -2;

  background: white;
`;

const theme = {
  borderRadius: "1rem",
  boxShadow: "0 5px 15px 1px rgba(0, 0, 0, .1)",
  orange: "#F79544",
  purple: "rebeccapurple",
  textShadow: "2px 4px 3px rgba(0,0,0,0.1)",
  turquoise: "#00bbc9", // Matches color used inilne in background-image of Background Container
  mediaQueries: {mobile: "only screen and (max-width: 480px)"},
};

const Layout = ({
  children,
  lockHowToPlayModal,
  setLockHowToPlayModal,
  setShowHowToPlayModal,
  showHowToPlayModal,
}) => {

  const location = useLocation();

  // On navigation, kill HowToPlayModal
  useEffect(() => {
    if ( !location.pathname.startsWith("/play/") ) {
      setLockHowToPlayModal( false );
      setShowHowToPlayModal( false );
    }
  }, [ location ]);

  // Hide modal, unless it's locked. Locking happens at the beginning of a game, in Play
  const hideModal = () => {
    if ( !lockHowToPlayModal ) {
      setShowHowToPlayModal( false );
    }
  };

  return (
    <ThemeProvider theme={ theme }>
      <HowToPlayModal
        hide={ hideModal }
        isLocked={ lockHowToPlayModal }
        isVisible={ showHowToPlayModal }
      />
      <GlobalStyles />
      <PersistentHeader setShowHowToPlayModal={ setShowHowToPlayModal } />
      <MainArea>
        { children }
      </MainArea>
      <PersistentFooter />
      <BackgroundContainer />
      <DoubleBackground />
      <ConnectionMonitor />
    </ThemeProvider>
  );
};

export default Layout;
