import {
  useEffect,
  useState,
} from "react";

import { getServerEpochMillis } from "services/datastore";

/**
 * Get a reliable (if not 100% accurate due to transit time) timestamp from the
 * server, to use instead of relying on local device time
 *
 * Known gap: We fetch server time once and calculate an offset from that. If
 * the user changes the device time during their session, this offset will no
 * longer be accurate, and the time will be wrong.
 */
export const useServerTimestamp = () => {

  const [ offsetMillis, setOffsetMillis ] = useState( null );

  // On mount, get the timestamp from the server and use it to calculate an offset from the local device time
  useEffect(() => {
    getAndSetOffsetMillis();
  }, []);

  // Helper to get server time and set offest
  const getAndSetOffsetMillis = async () => {
    const serverEpochMillis = await getServerEpochMillis();

    setOffsetMillis( serverEpochMillis - Date.now() );
  };

  // Export function to calculate the (approximate) epoch millis value based on server time
  const getServerTimestamp = () => {
    // Consider a difference of less than 10 seconds to be just due to transit time, so local time is trustable. Anything more than 10 secs, we assume local time isn't trustable, and use the offset to generate an approximate value
    return offsetMillis < 10000 ? Date.now() : Date.now() - offsetMillis;
  };

  return { getServerTimestamp };
};
