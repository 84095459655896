import {
  useEffect,
  useState,
} from "react";
import styled from "styled-components/macro";

import Spinner from "components/Spinner";

const PrimaryMessage = styled.p`
  font-weight: 500;
`;

const WaitTime = styled.p`
  font-style: italic;  
`;

const CountdownDisplay = ({visibleCountdownSecs}) => {

  const [ displayedSecs, setDisplayedSecs ] = useState( null );

  useEffect(() => {
    setDisplayedSecs( ( Math.floor(visibleCountdownSecs / 10) * 10 ) + 10 );
  }, [ visibleCountdownSecs ]);

  return (
    <div>
      <PrimaryMessage>
        {
          visibleCountdownSecs
            ? "Finding a partner for your game..."
            : "Hang tight, I'm still finding you a partner..."
        }
      </PrimaryMessage>
      <WaitTime>
        {
          visibleCountdownSecs
            ? `Estimated wait time: ${ displayedSecs } seconds`
            : "(Sorry for the delay)"
        }
      </WaitTime>
      <Spinner
        size="2rem"
        spinnerColor="orange"
      />
    </div>
  );
};

export default CountdownDisplay;
