import styled from "styled-components/macro";

import { useGameContext } from "context/GameContext";

import TurnRecap from "./TurnRecap";

const TurnCountDownContainer = styled.div`
  display: inline-block;
  opacity: ${ props => props.visible ? 1 : 0 };
  transition: opacity 0.5s ease;
  width: 50%;

  transform: background 0.25s ease;
`;

const Countdown = styled.div`
  font-size: 3rem;
  font-weight: 600;
  text-align: center;
`;

/**
 * Show the countdown until the next turn begins, and information about the
 * game (before turn 1), or what happened in the last turn (all other turns)
 * @returns
 */
const TurnCountdown = () => {

  const { countdownSecs } = useGameContext();

  return (
    <TurnCountDownContainer visible={ !!countdownSecs }>
      <Countdown>
        { countdownSecs ? countdownSecs : " "}
      </Countdown>
      <TurnRecap />
    </TurnCountDownContainer>
  );
};

export default TurnCountdown;
