import {
  useEffect,
  useState,
} from "react";
import styled from "styled-components/macro";

import { useCallContext } from "context/CallContext";
import { useGameContext } from "context/GameContext";

const ControlsContainer = styled.menu`
  list-style: none;
  margin: 0;
  padding: 0;

  li {
    list-style: none;
  }

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    & > li {
      display: inline-block;
      margin: 0 1rem;
    }
  }
`;

const MuteContainer = styled.li`
  margin-bottom: 1rem;
`;

const GameActionContainer = styled.li`
  margin: 0.5rem auto;
`;

// Shared styles for MuteButton and GameActionButton
const ControlButton = styled.button`
  border: 0;
  border-radius: ${ ({ theme }) => theme.borderRadius };
  box-shadow: ${ ({ theme }) => theme.boxShadow };
  cursor: pointer;
  font-size: 1rem;
  padding: 0.5rem 1rem;
  transition: transform 0.25s linear;

  // Hover and click effects
  &:hover {
    transform: scale(1.1);
  }
  &:active:hover, &:active {
    transform: translate(-0.1rem, 0.25rem);
  }
`;

const MuteButton = styled( ControlButton )`
  background: silver;
`;

const GameActionButton = styled( ControlButton )`
  // Undo some styles and set others for non-active game buttons
  ${ ({
    active,
    theme,
  }) => {
    if ( active ) {
      return `background: ${theme.orange};`;
    }
    return `
      background: silver;
      color: gray;
      cursor: default;

      &:hover {
        transform: none;
      }
      &:active:hover, &:active {
        transform: none;
      }
    `;
  }}

  // If button is in loading state, display loading/wait cursor on mouseover
  ${({ isLoading }) => isLoading ? "cursor: wait;" : "" };
`;

/**
 * Renders control buttons that are always on-screen during game. For now,
 * that's only the mute button
 */
const Controls = () => {

  /*
   * CONTEXT
   */

  const {
    isMuted,
    toggleMute,
  } = useCallContext();

  const {
    countdownSecs,
    gameIsFinished,
    localIsGuesser,
    proposeTurnEnd,
    turnCurrent,
  } = useGameContext();

  /*
   * STATE
   */

  // Should "Correct" and "Pass" buttons be clickable
  const [ correctButtonIsActive, setCorrectButtonIsActive ] = useState( false );
  const [ passButtonIsActive, setPassButtonIsActive ] = useState( false );

  // Should "Correct" and "Pass" buttons display loading state ("wait" cursor)?
  const [
    correctButtonIsLoading,
    setCorrectButtonIsLoading,
  ] = useState( false );
  const [
    passButtonIsLoading,
    setPassButtonIsLoading,
  ] = useState( false );

  /*
   * USE EFFECT
   */

  // When turn number changes, make buttons active to start the new turn
  useEffect(() => {
    // Only activate if we're on a valid turn (i.e. turn number is truthy)
    if ( turnCurrent ) {
      // Both buttons should be active
      setCorrectButtonIsActive( true );
      setPassButtonIsActive( true );
      // Neither has been clicked, so shouldn't be in loading state
      setCorrectButtonIsLoading( false );
      setPassButtonIsLoading( false );
    }
  }, [ turnCurrent ]);

  /*
   * Correct button should be active only when all of the following are true:
   * 1. Local user is NOT guesser
   * 2. We don't have a value for countdownSecs (we only have a value between
   * turns)
   * 3. The game isn't finished
   */
  useEffect(() => {
    setCorrectButtonIsActive(
      !(localIsGuesser || countdownSecs || gameIsFinished),
    );
  }, [
    localIsGuesser,
    countdownSecs,
    gameIsFinished,
  ]);

  /*
   * Pass button should be active only when all of the following are true:
   * 1. We don't have a value for countdownSecs (we only have a value between
   * turns)
   * 2. The game isn't finished
   */
  useEffect(() => {
    setPassButtonIsActive(
      !(countdownSecs || gameIsFinished),
    );
  }, [
    countdownSecs,
    gameIsFinished,
  ]);

  /*
   * HELPERS
   */

  // Only the hint-giver can say that a word was correctly guessed
  const proposeCorrect = () => {
    if ( !localIsGuesser && !countdownSecs ) {
      // Deactivate button to prevent multiple clicks - re-activated by turn change
      setCorrectButtonIsActive( false );
      setCorrectButtonIsLoading( true );
      proposeTurnEnd("CORRECT");
    }
  };

  // Can't propose a turn ends while we're counting down to it!
  const proposeIncorrect = () => {
    if ( !countdownSecs ) {
      // Deactivate button to prevent multiple clicks - re-activated by turn change
      setPassButtonIsActive( false );
      setPassButtonIsLoading( true );
      proposeTurnEnd("INCORRECT");
    }
  };

  /*
   * RENDER
   */

  return (
    <ControlsContainer>
      <MuteContainer>
        <MuteButton onClick={ toggleMute }>
          { isMuted ? "Unmute" : "Mute" }
        </MuteButton>
      </MuteContainer>
      <GameActionContainer>
        <GameActionButton
          active={ correctButtonIsActive }
          isLoading={ correctButtonIsLoading }
          onClick={ proposeCorrect }
        >
          Correct!
        </GameActionButton>
      </GameActionContainer>
      <GameActionContainer>
        <GameActionButton
          active={ passButtonIsActive }
          isLoading={ passButtonIsLoading }
          onClick={ proposeIncorrect }
        >
          Pass
        </GameActionButton>
      </GameActionContainer>
    </ControlsContainer>
  );
};

export default Controls;
