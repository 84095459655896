import { talkka } from "services/logging";
import { asyncFetchResult } from "./asyncFetchResult";

/**
 * Get the current time, in JavaScript epoch milliseconds, according to our
 * server
 *
 * NOTE: This won't be 100% accurate, since neither the endpoint nor the logic
 * in this application account for transit time for the request
 *
 * @returns { Number } JavaScript epoch milliseconds (i.e. result of Date.now())
 */
export const getServerEpochMillis = async () => {
  try {
    const result = await asyncFetchResult({endpoint: "get_epoch_millis"});

    if ( result.success ) {
      return result.data.epochMillis;
    }
    else {
      throw new Error("Cloud Function error - check logs");
    }
  }
  catch ( error ) {
    // Create logEvent so we can investigate, if needed
    talkka.warn(`Failed to get JS epoch timestamp from server with error: ${error}`);
    // Return local time -- better than nothing, and right 99.9% of the time
    return Date.now();
  }
};

/**
 * Check if user has correct version of play app
 *
 * @param { string } version to check
 * @returns { Boolean } true if there is a student with the given email
 */
export const getVersionCheck = async ( version ) => {
  try {
    // Use helper to dispatch request
    const result = await asyncFetchResult({
      endpoint: "get_app_version",
      body: { clientVersion: version },
    });

    if ( result.success ) {
      return result.data;
    }
    else {
      throw new Error("Cloud Function error - check logs");
    }
  }
  catch ( error ) {
    console.error("Failed to check app version");
    return false;
  }
};

/**
 * Check if a user (student) exists in Firebase with the given email address
 *
 * @param { string } email to check
 * @returns { Boolean } true if there is a student with the given email
 */
export const checkForUserWithEmail = async ( email ) => {
  try {
    // Use helper to dispatch request
    const result = await asyncFetchResult({
      endpoint: "check_email",
      body: { email },
    });

    if ( result.success ) {
      return result.data;
    }
    else {
      throw new Error("Cloud Function error - check logs");
    }
  }
  catch ( error ) {
    console.error(`Failed to confirm user with email ${ email } due to error ${ error }`);
    return false;
  }
};

// Create a Daily room for the game to take place in, and return room info
export const createDailyRoom = async ({ epochMillis }) => {
  try {
    const result = await asyncFetchResult({
      endpoint: "set_up_game",
      // Send unique value to prevent browser caching request - not used in back end
      epochMillis,
    });

    // If successful, send data to calling component
    if (result.success) {
      return result.data.roomInfo;
    }
    else {
      // If no success flag, throw error
      throw new Error("Cloud Function error - check logs");
    }
  }
  catch (error) {
    console.log(`Failed to set up Daily room with error: ${ error }`);
    return false;
  }
};

// Gets database info for logged-in teacher
export const fetchUserInfoFromDatabase = async () => {
  try {
    // Use helper to dispatch request
    const result = await asyncFetchResult({ endpoint: "get_one_user_by_token" });

    // If successful, send data to calling component
    if (result.success) {
      return result.data.user;
    }
    else {
      // If no success flag, throw error
      throw new Error("Cloud Function error - check logs");
    }
  }
  catch (error) {
    console.log(`Failed to get user info with error: ${error}`);
    return false;
  }
};

// Get token for indicated Daily room, with user given indicated name and id
export const getDailyToken = async ({
  roomName,
  userId,
  userName,
}) => {
  try {
    // Use helper to dispatch request
    const result = await asyncFetchResult({
      endpoint: "get_daily_token",
      body: {
        roomName,
        userId,
        userName,
      },
    });

    // If successful, send data to calling component
    if (result.success) {
      return result.data.token;
    }
    else {
      // If no success flag, throw error
      throw new Error("Cloud Function error - check logs");
    }
  }
  catch (error) {
    console.log(`Failed to get Daily token with error: ${error}`);
    return false;
  }
};

/**
 * Handle situation where student gets to StartGame but it times out
 *
 * @param { String } studentEmail
 * @param { String } studentName
 * @returns { Boolean } indicating if call succeeded
 */
export const handleStartGameTimeout = async ( {
  studentEmail, 
  studentName, 
}) => {

  const result = await asyncFetchResult({
    endpoint: "handle_start_game_timeout",
    body: { 
      studentEmail, 
      studentName, 
    },
  });

  // Return boolean indicating success or failure of call
  return result.success;
};

/**
 * Save event (typically error) info in Firestore. Only used with talkka.X
 * logging functions (see services/logging/talkka.js)
 *
 * @param { Object } logInfo - from talkka.X, contains info to save in
 * Firestore about the event we're recording
 * @returns { Boolean } indicating if call succeeded
 */
export const saveLogEvent = async (logInfo) => {

  // Pass logInfo directly through as body
  // WARNING: Changing the endpoint called by this function could result in infinite loops if there's an error during a saveLogEvent call. So if we do change the endpoint, also change the logic in the catch block of asyncFetchData to reflect that new endpoint
  const result = await asyncFetchResult({
    endpoint: "save_log_event",
    body: logInfo,
    // Dont retry this endpoint -- easy to get into an infinite loop, since most talkka.loggers call this function again
  });

  // Return boolean indicating success or failure of call
  return result.success;
};
