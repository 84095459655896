import { useEffect } from "react";

import { useNavigate } from "react-router-dom";

import { getCurrentUser } from "context/UserContext";

/**
 * If there is not a logged-in user, redirects to the home route instead of
 * rendering this component's children. Used to wrap the parent component for a
 * page (route) that should only be visible tologged-in users.
 *
 * @param { Element } children - should usually just be the one rendered
 * component for a route
 * @returns
 */
const RequireUser = ({ children }) => {

  const user = getCurrentUser();
  const navigate = useNavigate();

  useEffect(() => {
    if ( !user ) {
      navigate("/");
    }
  }, [ user ]);

  return children;
};

export default RequireUser;
