import styled from "styled-components/macro";

const SubmitInput = styled.input`
  background: white;
  border: 1px solid #0004;
  border-radius: ${ props => props.theme.borderRadius };
  box-shadow: ${ props => props.theme.boxShadow };
  color: black;
  cursor: pointer;
  font-size: 1.25rem;
  font-weight: 500;
  padding: 0.5rem 1.5rem;
  text-shadow: ${({ theme }) => theme.textShadow};
  transition: transform 0.25s ease;
  width: fit-content;

  &:hover {
    transform: scale(1.1);
  }
  &:active:hover, &:active {
    transform: translate(-0.1rem, 0.25rem);
  }
`;

/**
 * Styled submit button for forms
 */
const StyledSubmit = () => {
  return (
    <SubmitInput type="submit" />
  );
};

export default StyledSubmit;
