import styled from "styled-components/macro";

import { useGameContext } from "context/GameContext";

const InstructionContainer = styled.div`
background: ${ props => (
    props.gameIsFinished
      ? "gray"
      : props.localIsGuesser
        ? "royalblue"
        : "coral"
  )};
  color: white;
  padding: 0.5rem;
`;

/**
 * Renders in-the-moment instructions for what the local user should be doing
 * right now: Language to speak, and hinter/guesser
 */
const Instruction = () => {

  const {
    gameIsFinished,
    localIsGuesser,
  } = useGameContext();

  return (
    <InstructionContainer
      gameIsFinished={ gameIsFinished }
      localIsGuesser={ localIsGuesser }
    >
      Speak in SPANISH
      <br />
      {
        gameIsFinished
          ? "The game is finished"
          : localIsGuesser
            ? "Guess the word"
            : "Give hints - but don't say the word!"
      }
    </InstructionContainer>
  );
};

export default Instruction;
