import {
  useEffect,
  useRef,
  useState,
} from "react";
import {
  onValue,
  ref,
} from "firebase/database";

import { firebaseRealTimeDatabase } from "services/firebase";

import ConnectionWarning from "./ConnectionWarning";

/**
 * Alert the user whenever the connection to RTD is dropped, to let them know
 * that they may need to find a more stable connection to successfully play
 */
const ConnectionMonitor = () => {

  /*
   * STATE & REF
   */

  // Boolean for whether or not app is connectected to RTD at this moment
  const [ connectedToRTD, setConnectedToRTD ] = useState( true );

  // Boolean for whether or not ConnectionWarning should be shown
  const [ showConnectionWarning, setShowConnectionWarning ] = useState( false );

  // Ref to the timeout for showing the connection warning
  const showConnectionWarningTimeout = useRef( null );

  /*
   * USE EFFECT
   */
  // On mount, attach connection listener
  useEffect(() => {

    // Ref to special location where RTD logs connection state
    const connectedRef = ref(firebaseRealTimeDatabase, ".info/connected");

    // Make this app aware of RTD connection state!
    const unsubscribeFromOnValue = onValue(
      connectedRef,
      (snap) => {
        setConnectedToRTD( snap.val() );
      },
    );

    return unsubscribeFromOnValue;

  }, []);

  // When connection status changes, adjust show/hide of ConnectionWarning
  useEffect(() => {

    // If connected to RTD, clear timeout to show ConnectionWarning
    if ( connectedToRTD ) {
      setShowConnectionWarning( false );
      clearTimeout( showConnectionWarningTimeout.current );
      showConnectionWarningTimeout.current = null;
    }
    // If disconnected, set countdown to show ConnectionWarning
    else {
      showConnectionWarningTimeout.current = setTimeout(
        () => setShowConnectionWarning( true ),
        2000,
      );
    }

  }, [ connectedToRTD ]);

  /*
   * RENDER
   */

  return showConnectionWarning
    ? <ConnectionWarning />
    : null;
};

export default ConnectionMonitor;
