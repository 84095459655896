import {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useNavigate } from "react-router-dom";

import { useCallContext } from "context/CallContext";
import { getCurrentUser } from "context/UserContext";

import { useTimer } from "hooks/useTimer";

// Use RTDConnection class to access shared "state" in RTD
import RTDConnection from "./RTDConnection";
import { scoreTurns } from "./scoreTurns";

let sharedState;

// Context to export values to the Play component and children
const GameContext = createContext();

/**
 * Encapsulates game logic, and serves as bridge between shared state in RTD
 * and local logic in downstream components (Play and its children)
 */
export const GameContextProvider = ({ children }) => {

  /*
   * HOOKS
   */

  const navigate = useNavigate();

  const user = getCurrentUser();

  // Reusable timers for in-game countdowns

  // Count down 10 seconds before first turn of game starts
  const beforeGameTimer = useTimer( 10 );

  // Count down 4 seconds before every turn
  const beforeTurnTimer = useTimer( 4 );
  
  // Count down 3 minutes - the duration of the entire game
  const gameTimer = useTimer( 180 );
  
  // Count down 1 minute - the duration of one turn
  const turnTimer = useTimer( 60 );

  // Convenience array of all timers
  const allTimers = [
    beforeGameTimer,
    beforeTurnTimer,
    gameTimer,
    turnTimer,
  ];

  /*
   * CONTEXT
   */

  // Daily context wraps game context, so game can know when remote player is in Daily room
  const { remoteDailyParticipantAuthId } = useCallContext();

  /*
   * SHARED STATE - READ ONLY
   *
   * Values here are kept updated by RTD listeners. They're saved in refs
   * because their change shouldn't trigger a re-render
   *
   * WARNING: Don't write to any of these refs - they're updated by logic in
   * Cloud Functions that writes to sharedState in RTD, and are here to be read
   * and exported downstream to components
   */

  // Local copy of the turns object in RTD shared state
  // WARNING: Do not write to this value - just read it from sharedState and respond appropriately
  const READ_ONLY_TURNS = useRef( {} );

  // Remember the turn number (turnCurrent in sharedState) that we're currently on
  const READ_ONLY_TURN_NUMBER = useRef( 0 );

  // Populated in initializeGame, only used before game start to display info for correct role once turn 1 starts
  const READ_ONLY_LOCAL_IS_PLAYER_1 = useRef( null );

  // Name and authId for remote user (other player)
  const READ_ONLY_REMOTE_PLAYER = useRef( {} );

  // Information (name and id) for bundle selected for this game
  const READ_ONLY_BUNDLE_INFO = useRef( {} );

  /*
   * LOCAL STATE
   *
   * Traditional state - values are updated by functions called based on
   * changes in shared state, and the changed values here trigger re-renders in
   * children of GameContext
   */

  // Boolean indicating whether game is complete
  const [ gameIsFinished, setGameIsFinished ] = useState( false );

  // Array of objects, each consisting of a word used in a previous game turn, and a flag for whether they were correct or not
  const [ wordsUsed, setWordsUsed ] = useState([]);

  /*
   * USE EFFECT
   */

  // On unmount, stop timers and clear shared state (remove RTD subscriptions)
  useEffect(() => {
    return function GameContextCleanup() {
      allTimers.forEach( timer => timer?.clear() );
      sharedState?.clear();
    };
  }, []);

  // When a pre-turn countdown gets to 0, start the turn
  useEffect(() => {
    // If starting the first turn, start the game timer and the new turn
    if ( beforeGameTimer.secsRemaining === 0 ) {
      gameTimer.start();
      startCurrentTurn();
    }
    // If starting any other turn, just start the turn
    else if ( beforeTurnTimer.secsRemaining === 0 ) {
      startCurrentTurn();
    }
  }, [
    beforeGameTimer.secsRemaining,
    beforeTurnTimer.secsRemaining,
  ]);

  // When a turn timer hits 0, propose that the turn is over
  useEffect(() => {
    if ( turnTimer.secsRemaining === 0 ) {
      proposeTurnEnd( "TIMEOUT_TURN" );
    }
  }, [ turnTimer.secsRemaining ]);

  // When the game timer hits 0, propose that the turn is over because the game is over
  useEffect(() => {
    if ( gameTimer.secsRemaining === 0 ) {
      proposeTurnEnd( "TIMEOUT_GAME" );
    }
  }, [ gameTimer.secsRemaining ]);

  // When turn changes, update wordsUsed with value from previous turn
  useEffect(() => {
    // Only update if we have a next turn
    if ( READ_ONLY_TURN_NUMBER.current ) {
      // Do logic inside state setter, so we have access to current values of things not in the dependency array
      setWordsUsed( (prevState) => {
        const justFinishedTurn =
          READ_ONLY_TURNS.current?.[ READ_ONLY_TURN_NUMBER.current - 1 ];

        // If we have a new turn, add data to state. If not, don't change anything!
        return justFinishedTurn
          ? prevState.concat([{
            word: justFinishedTurn.word.es,
            correct: justFinishedTurn.endState === "CORRECT",
            localWasGuesser: justFinishedTurn.guesser === user.authId,
          }])
          : prevState;
      },
      );
    }
  }, [ READ_ONLY_TURN_NUMBER.current ] );

  /*
   * HELPERS - INTERNAL
   */

  // Handle starting a new turn, triggered when the pre-turn countdown hits 0
  const startCurrentTurn = () => {
    // Set the time for this turn
    turnTimer.start();
  };

  /*
   * HELPERS - EXPORTED
   */

  /**
   * Sets up RTD listeners and logic for the game
   *
   * @param { String } gameInfoPath - unique path identifying the location of this game in RTD
   * @param { Boolean } localIsPlayer1 - true if local player is player1
   */
  const initializeGame = async ({
    gameInfoPath,
    localIsPlayer1,
  }) => {

    // Set initial value for who is the guesser
    READ_ONLY_LOCAL_IS_PLAYER_1.current = localIsPlayer1;

    // Instantiate instance of sharedState pointed to correct location in RTD, save it in global variable
    sharedState = new RTDConnection( gameInfoPath );

    // Check if there's a completed game at the indicated location. If so, this indicates that the user is returning to an old game, and should be redirected to the lobby
    const existingGameInfo = await sharedState.readOnceAsync();
    if (
      // If the game is marked as finished, then it's already done!
      existingGameInfo?.gameState === "FINISHED"
      // If the player has already stamped his/her info, then push 'em back to the lobby
      // WARNING TODO: This means that a refresh during a game will result in the player being pushed back to the lobby
      || ( localIsPlayer1 && existingGameInfo?.player1 )
      || ( !localIsPlayer1 && existingGameInfo?.playe2 )
    ) {
      return navigate("/lobby", { replace: true });
    }

    // Check if local player is player1 or player2
    const playerNumber = localIsPlayer1 ? 1 : 2;

    // Stamp that we've joined the class. When both players have stamped, CF logic that starts the game executes
    sharedState.write({[`player${ playerNumber }`]: user });

    // Set up listener to update info on remote player
    sharedState.listen({
      location: `player${ playerNumber === 1 ? 2 : 1 }`,
      callback: ( playerInfo ) => {
        if ( playerInfo ) {
          READ_ONLY_REMOTE_PLAYER.current = playerInfo;
        }
      },
    });

    // Set up listener to trigger turn changes
    sharedState.listen({
      location: "turnCurrent",
      callback: ( turnCurrent ) => {
        // Don't respond to values that aren't valid turn numbers
        if (
          typeof turnCurrent === "number"
          && turnCurrent > 0
        ) {
          // Set turn number value in local ref
          READ_ONLY_TURN_NUMBER.current = turnCurrent;

          // Clear turn timer for previous turn
          turnTimer.clear();

          // On any turn except turn 1 (started by startGame, as used in Play), start the pre-turn timer
          if ( turnCurrent !== 1 ) {
            beforeTurnTimer.start();
          }
        }
      },
    });

    // Set up listener to keep local read-only copy of sharedState turns object
    sharedState.listen({
      location: "turns",
      callback: ( turnsObject ) => {
        READ_ONLY_TURNS.current = turnsObject;
      },
    });

    // Set up listener to trigger end of game
    sharedState.listen({
      location: "gameState",
      callback: ( gameState ) => {
        if ( gameState === "FINISHED" ) {
          setGameIsFinished( true );
        }
      },
    });

    // Update bundle info, once it's written
    sharedState.listen({
      location: "wordBundle",
      callback: ( bundleInfo ) => {
        READ_ONLY_BUNDLE_INFO.current = bundleInfo;
      },
    });

    return true;
  };

  // Write object to RTD that proposes an endState for this turn. CF then checks that this is a valid end state for the turn and, if so, ends it
  const proposeTurnEnd = ( proposedEndState ) => {
    sharedState.write({ proposedTurnEnd: {
      fromPlayer: user.authId,
      turnNumber: READ_ONLY_TURN_NUMBER.current,
      proposedEndState,
    }});
  };

  const startGame = () => {
    beforeGameTimer.start();
  };

  /*
   * RENDER
   */

  return (
    <GameContext.Provider
      value={{
        initializeGame,
        proposeTurnEnd,
        startGame,
        bundleInfo: READ_ONLY_BUNDLE_INFO.current,
        countdownSecs: READ_ONLY_TURN_NUMBER.current === 1
          ? beforeGameTimer.secsRemaining
          : beforeTurnTimer.secsRemaining,
        currentTurnInfo:
          READ_ONLY_TURNS.current?.[ READ_ONLY_TURN_NUMBER.current ],
        gameIsFinished,
        localIsGuesser:
          READ_ONLY_TURNS.current?.[ READ_ONLY_TURN_NUMBER.current ]?.guesser
            ?  READ_ONLY_TURNS.current?.[ READ_ONLY_TURN_NUMBER.current ].guesser === user.authId
            : READ_ONLY_LOCAL_IS_PLAYER_1.current,
        previousTurnInfo:
          READ_ONLY_TURNS.current?.[ READ_ONLY_TURN_NUMBER.current - 1 ],
        remotePlayer: READ_ONLY_REMOTE_PLAYER.current,
        remotePlayerIsPresent:
          !!remoteDailyParticipantAuthId && !!READ_ONLY_REMOTE_PLAYER.current,
        score:
          READ_ONLY_TURNS.current ? scoreTurns( READ_ONLY_TURNS.current ) : 0,
        secsLeftInGame: gameTimer.secsRemaining,
        secsLeftInTurn: turnTimer.secsRemaining,
        turnCurrent: READ_ONLY_TURN_NUMBER.current,
        wordsUsed,
      }}
    >
      { children }
    </GameContext.Provider>
  );
};

export const useGameContext = () => useContext( GameContext );
