import {
  useEffect,
  useState,
} from "react";
import styled from "styled-components/macro";

import { useGameContext } from "context/GameContext";
import { getCurrentUser } from "context/UserContext";

import GameWords from "./GameWords";

const TeamName = styled.h2`
  font-size: 1.15rem;
  margin: 0.25rem;
`;

const Small = styled.span`
  font-size: 0.75em;
  font-weight: 400;
`;

const RemotePlayerIndicator = styled.span`
  background: ${ props => props.present ? "green" : "red" };
  border-radius: 50%;
  box-shadow:
    0 0 0.5rem 0.1rem ${ props => props.present ? "#0f0a" : "#f338"},
    inset 0 0 0.25rem #fff8;
  display: inline-block;
  height:0.75rem;
  margin: 0 0 0.25rem 0.25rem;
  width: 0.75rem;
`;

const GameInfoList = styled.ul`
  list-style: none;
  margin: 0.5rem 0;
  padding: 0;

  li {
    margin-bottom: 0.25rem;
  }
`;

/**
 * Displays information about the currently-ongoing game
 */
const GameInfo = () => {

  const user = getCurrentUser();

  const {
    bundleInfo,
    remotePlayer,
    remotePlayerIsPresent,
    secsLeftInGame,
  } = useGameContext();

  const [ humanFriendlyGameTime, setHumanFriendlyGameTime ] = useState( "" );

  // As game timer ticks down, compute human-friendly m:ss format to display remaining time
  useEffect(() => {
    const minsLeft = Math.floor( secsLeftInGame / 60 );
    const secsLeft = secsLeftInGame % 60;
    const prettySecsLeft = secsLeft < 10
      ? `0${secsLeft}`
      : secsLeft;
    setHumanFriendlyGameTime( `${minsLeft}:${prettySecsLeft}` );
  }, [ secsLeftInGame ]);

  return (
    <section>
      <TeamName>
        { user.name } <Small>(you)</Small> &amp; { remotePlayer.name }<RemotePlayerIndicator present={ remotePlayerIsPresent} />
      </TeamName>
      <GameInfoList>
        <li>
          Topic: { bundleInfo?.name }
        </li>
        <li>
          Time left in game: {
            secsLeftInGame
              ? humanFriendlyGameTime
              : "-:--"
          }
        </li>
      </GameInfoList>
      <GameWords />
    </section>
  );
};

export default GameInfo;
