import React, {
  useEffect,
  useState,
  useContext,
} from "react";
import { onAuthStateChanged } from "firebase/auth";

import { firebaseAuth } from "services/firebase";
import { fetchUserInfoFromDatabase } from "services/datastore";

import versionJSON from "version/version";
const { version } = versionJSON;

export const UserContext = React.createContext();

/**
 * Context that provides Firebase info about logged-in student (if any), or
 * otherwise provides basic user-inputted info about anonymous student.
 */
export const UserContextProvider = ({ children }) => {

  // Initialize currentUser to the value found in localStorage, or null
  const [ currentUser, setCurrentUser ] = useState(
    localStorage.getItem("currentUser")
      ? JSON.parse(localStorage.getItem("currentUser"))
      : null,
  );

  // On load, set auth state listener that keeps currentUser up-to-date on user login
  useEffect(() => {

    onAuthStateChanged(firebaseAuth, async ( user ) => {

      // If we have a user, set info in state to pass through context
      if ( user ) {

        // Get user info either from Firebase (if user has Speak App account), or localStorage (if user is anonymous)
        const userInfo = user.isAnonymous
          ? JSON.parse( localStorage.getItem("anonUser") )
          : await fetchUserInfoFromDatabase();

        // If we have user info, set it in state so it's exposed via context
        if ( userInfo ) {
          // Add auth uid to user info
          userInfo.authId = user.uid;

          // Add app version and userAgent
          userInfo.appVersion = version;
          userInfo.userAgent = window?.navigator?.userAgent;

          setCurrentUser( userInfo );
        }

        // If we just set an anonymous user, remove the temporary localStorage key (anonUser) we used to generate the permanent currentUser in localStorage and this context
        if ( user.isAnonymous ) {
          localStorage.removeItem("anonUser");
        }
      }
      // If we don't have user info, that means we logged out!
      else {

        // Clear local state - also removes from localStorage via useEffect below
        setCurrentUser(null);
      }

    });

  }, []);

  // When current user changes, save in local storage. Allows info to persist through browser refresh
  useEffect(() => {

    // If we have info, set it in localStorage
    if ( currentUser ) {
      localStorage.setItem(
        "currentUser",
        JSON.stringify(currentUser),
      );
    }
    // If we don't have info, remove the key from localStorage
    else {
      localStorage.removeItem( "currentUser" );
    }

  }, [ currentUser ]);

  return (
    <UserContext.Provider
      value={{
        currentUser,
        setCurrentUser,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

// Helper function to get current user
export const getCurrentUser = () => {
  // Grab context to use
  const context = useContext( UserContext );

  // Return context value if we have it, otherwise indicate that we have no user
  return (
    context?.currentUser
      ? context.currentUser
      : false
  );
};
