import { Link } from "react-router-dom";

/**
 * Renders a basic 404 page
 *
 * @returns
 */
const FourOhFour = () => (
  <main>
    <p>
      There's nothing here!
    </p>
    <p>
      <Link to="/">Go home</Link>
    </p>
  </main>
);

export default FourOhFour;
