import {
  get,
  onValue,
  ref,
  update,
} from "firebase/database";

import { firebaseRealTimeDatabase } from "services/firebase";

class RTDConnection {

  /*
   * INSTANCE PROPERTIES
   */

  // Path that points to location of info for this game in RTD
  basePath;

  // Array of unsubscribe functions for listeners, used for cleanup in clear
  unsubscribeFunctions = [];

  /*
   * CONSTRUCTOR
   */

  // To construct class instance, we need a path to a location in RTD
  constructor( gameInfoPath ) {
    this.basePath = `${gameInfoPath}/gameInfo`;
  }

  /*
   * INSTANCE METHODS
   */

  /**
   * Remove all RTD subscriptions, thus severing app's connection to shared
   * state in RTD
   */
  clear = () => {
    // If the function exists, call it!
    this.unsubscribeFunctions.forEach(
      (func) => func ? func() : null,
    );
  };

  /**
   * Set up a listener at the indicated location in RTD
   */
  listen = ({
    location = "",
    callback = ( value ) => console.log( value ),
  }) => {
    // Set up listener and put unsubscribe function in array used in clear
    this.unsubscribeFunctions.push(
      onValue(
        ref(
          firebaseRealTimeDatabase,
          `${this.basePath}${ location ? `/${location}` : "" }`,
        ),
        ( snap ) => {

          // Extract the value from the database, and pass it to the given function
          const val = snap.val();
          callback( val );
        },
      ),
    );
  };

  /**
   * Reads the value of the gameInfo (optionally targeted to the specified
   * location) once
   *
   * @param { String } location [optional] - specific path within gameInfo to
   * read
   * @returns info from the given location (or top-level gameInfo, if location
   * not specified), or null if no data exists
   */
  readOnceAsync = async ( location = "" ) => {
    const readFromRef = ref(
      firebaseRealTimeDatabase,
      `${this.basePath}${ location ? `/${location}` : "" }`,
    );
    return (await get( readFromRef ))?.val();
  };

  /**
   * Write the given key/value pair(s) to the top level of the game info object
   * in RTD. Will either overwrite an existing value at the key, or create a
   * new key/value pair on the object.
   * 
   * Can write nested values by nesting within the info object. For example,
   * calling write({nesting: {it: "works!"}}) results in the same structure in
   * RTD:
   *          [gameInfoPath]: { nesting: { it: "works!" } }
   *
   * @param { Object } info - Object with key/value pair(s) to write to top
   * level of RTD object
   */
  write = ( info ) => {
    update(
      ref(
        firebaseRealTimeDatabase,
        `${this.basePath}`,
      ),
      info,
    );
  };
}

export default RTDConnection;
