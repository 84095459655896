import ReactLoading from "react-loading";
import styled, { useTheme } from "styled-components/macro";

const LoadingContainer = styled.div`
  /* DISPLAY */
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;

  font-size: 5rem;
`;

const LoadingText = styled.p`
  font-size: 1rem;
  margin: 0.5rem;
`;

/**
 * Render a spinning loading component
 * 
 */
const Loading = ({
  size = "1em",
  text = "Loading...",
}) => {

  const theme = useTheme();

  return (
    <LoadingContainer>
      <ReactLoading
        color={ theme.orange }
        height={ size }
        type="spin"
        width={ size }
      />
      {// If we have text to display, do so!
        text
          ? (
            <LoadingText>
              { text }
            </LoadingText>
          )
          : null
      }
    </LoadingContainer>
  );
};

export default Loading;
