import { useEffect } from "react";
import {
  useNavigate,
  useSearchParams,
} from "react-router-dom";


import { getCurrentUser } from "context/UserContext";

/**
 * Component that directs traffic arriving at the root path based on login
 * status. If there is no logged-in user, redirects to the /login route. If
 * there is a logged-in user, the fact that this component is wrapped in
 * PushUserToLobby... pushes the user to the /lobby route.
 * 
 * @returns
 */
const FrontDoor = () => {

  /*
   * HOOKS
   */

  // Get user info
  const user = getCurrentUser();

  // Use react-router navigation (v6 version of v5's useHistory)
  const navigate = useNavigate();

  // Get search params. Hook returns value and setter (like React useState), but we just need the value
  const [ searchParams ] = useSearchParams();

  /*
   * USE EFFECT
   */

  // On load, check for current user
  useEffect(() => {

    // If we don't have a user, go to login page
    if ( !user ) {
      // Pass along data from query string (if any)
      const bundle = searchParams.get("bundle");
      const email = searchParams.get("email");
      const name = searchParams.get("name");
      navigate("/login", { state: {
        bundle,
        email,
        name,
      }});
    }
    // If we do have a user, this component won't render, because it is wrapped in PushUserToLobby

  }, [ user ]);

  return (
    <main>
      There is no spoon
    </main>
  );
};

export default FrontDoor;
