import styled from "styled-components/macro";

import versionJSON from "version/version";
const { version } = versionJSON;

const Footer = styled.footer`
  font-size: 0.8rem;
  margin-top: 2rem;
  text-align: center;
`;

/**
 * Footer rendered at bottom of all pages
 */
const PersistentFooter = () => {
  return (
    <Footer>
      Have an idea to make Talkkaround better? <a href="mailto:amigos@beepboop.us?subject=Talkkaround Feedback">Let us know!</a>
      <br />
      Powered by&nbsp;
      <a href="https://speak.beepboop.us?utm_source=playapp&utm_medium=playapp&utm_campaign=game-footer" target="_blank">
        Beepboop
      </a>
      <br />
      { version }
    </Footer>
  );
};

export default PersistentFooter;
