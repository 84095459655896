import styled from "styled-components/macro";

// Based on CSS from https://projects.lukehaas.me/css-loaders/
const SpinnerComponent = styled.div`
  animation: load3 1.4s infinite linear;
  -webkit-animation: load3 1.4s infinite linear;
  background: ${({ spinnerColor }) => spinnerColor};
  background: -moz-linear-gradient(left, ${({ spinnerColor }) => spinnerColor} 10%, rgba(255, 255, 255, 0) 42%);
  background: -webkit-linear-gradient(left, ${({ spinnerColor }) => spinnerColor} 10%, rgba(255, 255, 255, 0) 42%);
  background: -o-linear-gradient(left, ${({ spinnerColor }) => spinnerColor} 10%, rgba(255, 255, 255, 0) 42%);
  background: -ms-linear-gradient(left, ${({ spinnerColor }) => spinnerColor} 10%, rgba(255, 255, 255, 0) 42%);
  background: linear-gradient(to right, ${({ spinnerColor }) => spinnerColor} 10%, rgba(255, 255, 255, 0) 42%);
  border-radius: 50%;
  font-size: 0.5rem;
  height: ${ ({ size }) => size };
  margin: 1rem auto;
  position: relative;
  text-indent: -9999em;
  transform: translateZ(0);
  -ms-transform: translateZ(0);
  -webkit-transform: translateZ(0);
  width: ${ ({ size }) => size };
  
  &:before {
    background: ${({ spinnerColor }) => spinnerColor};
    border-radius: 100% 0 0 0;
    content: "";
    height: 50%;
    left: 0;
    position: absolute; // against Spinner
    top: 0;
    width: 50%;
  }
  &:after {
    /* POSITION */
    position: absolute; // against Spinner
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;

    background: ${ ({
    backgroundColor: color, theme, 
  }) => (
    color === "orange"
      ? theme.orange
      : color === "turquoise"
        ? theme.turquoise
        : "white"
  )};
    border-radius: 50%;
    content: "";
    height: 75%;
    margin: auto;
    width: 75%;
  }

  @-webkit-keyframes load3 {
    0% {
      transform: rotate(0deg);
      -webkit-transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
      -webkit-transform: rotate(360deg);
    }
  }
  @keyframes load3 {
    0% {
      transform: rotate(0deg);
      -webkit-transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
      -webkit-transform: rotate(360deg);
    }
  }
`;
/**
 * Loading spinner
 */
const Spinner = ({
  backgroundColor = false,
  spinnerColor = "#ffffff",
  size = "5rem", 
}) => (
  <SpinnerComponent
    backgroundColor={ backgroundColor }
    spinnerColor={ spinnerColor }
    size={ size }
  />
);

export default Spinner;
