import { useNavigate } from "react-router-dom";
import styled from "styled-components/macro";

import { useCallContext } from "context/CallContext";
import Button from "components/Button";

const ErrorMessageOverlay = styled.div`
  /* DISPLAY */
  display: flex;
  align-items: center;
  justify-content: center;

  /* POSITION */
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  background: linear-gradient(rgba(0,0,0,0.7),rgba(0,0,0,0.7));
`;

const ErrorMessageContainer = styled.section`
  background: white;
  border-radius: ${({ theme }) => theme.borderRadius};
  color: black;
  padding: 1rem;

  h2 {
    margin: 0;
  }
`;

const ButtonContainer = styled.div`
  text-align: center;

  button {
    margin: 0 1rem;
  }
`;

/**
 * Display a basic error modal with actions the user can take to try to remedy
 *
 * @returns
 */
const ErrorMessage = () => {

  const navigate = useNavigate();

  const {
    callError,
    isRoomClosed,
  } = useCallContext();

  return callError || isRoomClosed
    ? (
      <ErrorMessageOverlay>
        <ErrorMessageContainer>
          <h2>{isRoomClosed ? "Game Over" : "Oh no!"}</h2>
          <p>
            {isRoomClosed
              ? "This game is over and you've been disconnected from your partner. Head back to the lobby to play again!"
              : "Something went wrong, and we can't connect to your game."
            }
          </p>
          {// At room close, ask for feedback
            isRoomClosed
            && (
              <p>
                We're constantly improving Talkkaround, and would love your feedback. <a href="mailto:amigos@beepboop.us?subject=Talkkaround Feedback">Shoot us an email</a> to let us know what you think!
              </p>
            )
          }
          <ButtonContainer>
            {// Only show try again button if we have an error
              callError
                && (
                  <Button
                    onClick={ () => window.location.reload() }
                    secondary
                    text="Try Again"
                  />
                )
            }
            <Button
              onClick={ () => navigate("/lobby") }
              text="Back to Lobby"
            />
          </ButtonContainer>
        </ErrorMessageContainer>
      </ErrorMessageOverlay>
    )
    : null;
};

export default ErrorMessage;
