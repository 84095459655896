import {
  useEffect,
  useRef,
  useState,
} from "react";
import { useNavigate } from "react-router-dom";
import {
  get,
  onValue,
  ref,
  runTransaction,
  set,
} from "firebase/database";

import { OFFERS_PREFIX } from "const";

import { getCurrentUser } from "context/UserContext";

import { useServerTimestamp } from "hooks/useServerTimestamp";

import { firebaseRealTimeDatabase } from "services/firebase";

/**
 * Centralize logic dealing with offers in Lobby in one file.
 */
export const useOffers = () => {

  /*
   * CONTEXT & HOOKS
   */

  // User info from context
  const user = getCurrentUser();

  const navigate = useNavigate();

  const { getServerTimestamp } = useServerTimestamp();

  // Reference (x2!) to this user's lobby offer location in RTD
  // Using React useRef to wrap a Firebase RTD ref so changes here don't cause a re-render
  const UNIQUE_OFFER_REF = useRef(
    ref(
      firebaseRealTimeDatabase,
      `${OFFERS_PREFIX}/${user.authId}`,
    ),
  ).current;

  /*
   * STATE
   */

  // Keep a copy of all lobby game offers (except any created by local user) in local state
  const [ offers, setOffers ] = useState( [] );

  /*
   * USE EFFECT
   */

  // On load, set up connection to monitor all lobby game offers
  useEffect(() => {
    
    // Monitor all game offers in the lobby
    const allOffersRef = ref(
      firebaseRealTimeDatabase,
      OFFERS_PREFIX,
    );

    // When offers are added or removed, update current state
    const unsubscribeFromOffers = onValue(
      allOffersRef,
      async ( snap ) => {
        // Extract object with offers from RTD
        const allOffersObject = snap.val();

        // Check for an offer created by the local user
        const localUserCreatedOffer = allOffersObject?.[user.authId];

        // If we have an offer created by local user, remove it from the list - we don't want to consider it for joining

        // If we have a local-user-created offer, and that offer now has a player2, then someone joined the local user's offer!
        if ( localUserCreatedOffer?.player2 ) {
          // Helper function unmounts this component and navigates to /startGame
          return startGameFromOffer( localUserCreatedOffer );
        }

        // Delete the user-created offer from our object so it doesn't get used in logic below this point.
        if ( localUserCreatedOffer ) {
          delete allOffersObject[user.authId];
        }

        // Extract remaining offer info (all offers except local-user-created)
        const allOffersArray = allOffersObject
          ? Object.values( allOffersObject )
          : [];

        // Check if any of the offers have been accepted by the local user, as indicated by player2 having the same authId as the local user
        const offerAcceptedByLocalUser = allOffersArray.find(offer => (
          offer?.player2?.authId === user.authId
        ));

        if ( offerAcceptedByLocalUser ) {
          return startGameFromOffer( offerAcceptedByLocalUser );
        }

        // If we have a local-user-created offer AND other offers, then something went funky. Decide if this user should join another one
        else if (
          allOffersArray.length
          && localUserCreatedOffer
        ) {

          // If we have an offer that has a player1 but no player2, and was created before the local user's offer, it's acceptable and we should join it!
          const acceptableOffer = allOffersArray.find( offer => (
            offer.player1
            && !offer.player2
            && offer.offerCreatedAtEpochMillis < localUserCreatedOffer.offerCreatedAtEpochMillis
          ));

          // If we found an acceptable offer, join it!
          if ( acceptableOffer ) {
            // Delete existing offer to accept the new one
            deleteOffer();

            // Accept offer and check result
            const finalOffer = await acceptOffer( acceptableOffer );

            // If we didn't successfully join the offer, recreate the one we just deleted, instead
            // Doing this after deletion because if acceptOffer succeeds, we may not get this far before the process is interrupted by the push to /startGame
            if ( finalOffer?.player2?.authId !== user.authId ) {
              createOffer();
            }
          }
        }

        // Set offers in state
        setOffers( allOffersArray );
      },
    );

    // On unmount, remove RTD listener
    return unsubscribeFromOffers;

  }, []);

  /*
   * HELEPRS
   */

  /**
   * Transactionally accept an offer to play a game
   *
   * @param { Offer } offerToAccept
   * @returns { Offer } final value from the completed transaction
   */
  const acceptOffer = async ( offerToAccept ) => {

    // Create a ref to this offer in RTD
    const offerRef = ref(
      firebaseRealTimeDatabase,
      `${OFFERS_PREFIX}/${offerToAccept.player1.authId}`,
    );

    // Transactionally run the acceptance of the offer. If the offer is modified (i.e. accepted by another student) during the transaction, the write will fail and the provided callback will re-run with the updated data. This ensures that two users can't join the same offer as player2.
    const { snapshot } = await runTransaction(offerRef, (offer) => {
      // Don't join an offer that's already been accepted, as indicated by the fact that it has player2 stamped, or has been deleted (after game was created)
      if (
        !offer
        || offer.player2
      ) {
        // Return the value unchanged
        return offer;
      }
      // If offer hasn't been accepted, accept it!
      else {

        // Return offer stamped with player2 info
        return {
          ...offer,
          // Stamping player2 with local user info triggers game-starting logic for both this user and the user who created the offer
          player2: user,
          offerAcceptedAtEpochMillis: getServerTimestamp(),
        };
      }
    });

    // Return data in RTD after transaction is complete
    return snapshot?.val();
  };

  // Create a lobby offer for this user (so other users can join them in a game)
  const createOffer = async () => {

    // Check if an offer was already created here. If so, don't do anything!
    const existingOffer = (await get( UNIQUE_OFFER_REF )).val();

    if ( existingOffer ) {
      return;
    }

    // Set a new offer at the ref
    // NOTE: No need to check for existing value -- The use of set here, combined with the use of the user's unique authId in the path for this offer listing, ensures that a user can only create one lobby offer, and that every attempt to modify this offer simply overwrites previous values.
    set(
      UNIQUE_OFFER_REF,
      {
        // Player who made the offer is player 1
        player1: user,
        // Key not written in RTD due to null value - here just for reference
        player2: null,
        offerCreatedAtEpochMillis: getServerTimestamp(),
      },
    );
  };

  // Delete this user's lobby offer
  const deleteOffer = () => {
    // Per RTD docs, passing null to .set() is equivalent to calling .remov()
    set(
      UNIQUE_OFFER_REF,
      null,
    );
  };

  // Helper function to start the game-joining process once an offer is accepted
  const startGameFromOffer = ( acceptedOffer ) => {
    // Go to startGame route, and pass along basic offer info in state
    navigate(
      `/startGame/${acceptedOffer.player1.authId}`,
      { state: acceptedOffer },
    );
  };

  /*
   * RETURN
   */

  return {
    acceptOffer,
    createOffer,
    deleteOffer,
    offers,
  };
};
