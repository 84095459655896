
import {
  useEffect,
  useState,
} from "react";
import { useLocation } from "react-router-dom";
import styled from "styled-components/macro";

import versionJSON from "version/version";
const { version } = versionJSON;

import Menu from "./Menu";

const HeaderContainer = styled.header`
  background: white;
  box-shadow: ${ props => props.theme.boxShadow };
  margin-bottom: 0.5rem;
  padding: 0.5rem;
  position: relative;

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    padding-bottom: 2rem;
  }
`;

const HeaderVersion = styled.div`
  position: absolute;
  bottom: 0;

  font-size: 0.75rem;
`;

const PageHeading = styled.h1`
  margin: 0;
  text-align: center;
`;

/**
 * Header component rendered in Layout - appears on every route in site
 *
 * @returns
 */
const PersistentHeader = ({ setShowHowToPlayModal }) => {

  const location = useLocation();

  const [ pageTitle, setPageTitle ] = useState( "" );

  // On location change, recalculate page title
  useEffect( () => {
    
    if ( location.pathname.startsWith("/lobby") ) {
      setPageTitle( "Talkkaround (beta): Lobby" );
    }
    else if ( location.pathname.startsWith("/play") ) {
      setPageTitle( "Talkkaround (beta): Play Game" );
    }
    else {
      setPageTitle( "Talkkaround (beta)" );
    }

  }, [ location ]);

  /*
   * RENDER
   */

  return (
    <HeaderContainer>
      <HeaderVersion>
        { version }
      </HeaderVersion>
      <PageHeading>
        { pageTitle }
      </PageHeading>
      <Menu setShowHowToPlayModal={ setShowHowToPlayModal } />
    </HeaderContainer>
  );
};

export default PersistentHeader;
