import styled from "styled-components/macro";

import { useGameContext } from "context/GameContext";

import TurnTimer from "./TurnTimer";
import ViewSlider from "./ViewSlider";

const TurnAreaContainer = styled.section`
  background: white;
  border: 1px solid black;
  overflow: hidden;
  position: relative;
  height: ${({ restrictHeight }) => restrictHeight ? "12rem" : "auto" };
  vertical-align: middle;
  width: 19rem;
`;

/**
 * Display the area of the screen that shows the word (to the hinter) and 
 * appropriate end-turn buttons
 * @returns
 */
const TurnArea = () => {

  const { gameIsFinished } = useGameContext();

  return (
    <TurnAreaContainer restrictHeight={ !gameIsFinished }>
      <ViewSlider />
      <TurnTimer />
    </TurnAreaContainer>
  );
};

export default TurnArea;
