import {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import Daily from "@daily-co/daily-js";

import { getCurrentUser } from "context/UserContext";

import { getDailyToken } from "services/datastore";

import {
  Audio,
  playAudio,
} from "./Audio";

const CallContext = createContext();

// A note on semantics: This hook, and things that happen while there are people communicating via the Daily API, use the noun Call. Our setup functions, and things that refer to creating the location on the Daily server in which the Call will take place, use the noun Room.

/**
 * Context to interact with Daily call
 * 
 * @param { Nodes } children - of this context
 * @returns
 */
export const CallContextProvider = ({ children }) => {

  /*
   * HOOKS
   */

  const user = getCurrentUser();

  /*
   * CONSTANTS
   */

  // Save Daily Call Object in ref so changes (if any) don't result in re-renders
  const DailyCallRef = useRef( Daily.createCallObject({
    audioSource: true,
    videoSource: false,
  }) );
  // Convenience variable points to current value of DailyCallRef
  const DailyCallObject = DailyCallRef.current;

  /*
   * STATE
   */

  // Boolean indicating if an error occurred in this call
  const [ callError, setCallError ] = useState( false );

  // Array of users in this call, extracted from DailyCallObject.participants()
  const [ participants, setParticipants ] = useState( null );

  // Information about the Daily room, from return from Daily API on room creation
  const [ roomInfo, setRoomInfo ] = useState( null );

  // Use Daily user_name to get remote user auth_id. Null implicitly indicates that other player is not in Daily room
  const [
    remoteDailyParticipantAuthId,
    setRemoteDailyParticipantAuthId,
  ] = useState( null );

  // Flag flips to true when Daily room expires
  const [ isRoomClosed, setIsRoomClosed ] = useState( false );

  /*
   * USE EFFECT
   */

  // Attach Daily event handlers, and gracefully end call on unmount
  useEffect( () => {

    // We need a valid call object to do anything!
    if ( !DailyCallObject ) {
      return;
    }

    // Whenever a participant joins or leaves, updates our concept of participants in state
    DailyCallObject.on("participant-joined", handleParticipantJoined);
    DailyCallObject.on("participant-left", handleParticipantLeft);
    DailyCallObject.on("participant-updated", updateCallParticipants);

    // Handle Daily errors
    DailyCallObject.on("error", handleError);

    // On unmount, disconnect from Daily room and free resources by calling destroy() on the call object
    return function dailyCallCleanup() {
      DailyCallObject?.destroy();
    };

  }, [ DailyCallObject ] );

  useEffect(() => {

    if (
      // We need both roomInfo and user to join the call
      !roomInfo
      || !user
    ) {
      return;
    }

    // Join the call using async helper. Re-joining a call that's already joined is benign - Daily will raise a warning and take no action
    asyncJoinCall();

  }, [
    roomInfo,
    user,
  ]);

  /*
   * HELPERS - INTERNAL
   */

  // Join the Daily call - called once we have roomInfo and user
  const asyncJoinCall = async () => {

    // Fetch token for the given room from the back end
    const token = await getDailyToken({
      roomName: roomInfo.name,
      userId: user.authId,
      userName: user.name,
    });

    // Use the token to join the indicated room
    await DailyCallObject.join({
      url: roomInfo.url,
      token,
    });
  };

  // Handle Daily errors - for now, just send an "oh crap" signal to Play so user can try another game
  const handleError = ( event ) => {

    // Handle end-of-room error from Daily separately
    if (
      event.errorMsg === "Meeting has ended"
      || event.error?.type === "exp-room"
    ) {
      setIsRoomClosed( true );
    }

    // If not end-of-room error, set generic error alert
    else {
      // TEMP - emit error in console for development
      console.error("DAILY ERROR LOGGED IN CALLCONTEXT", event);
  
      // Set state flag that something went wrong
      setCallError( true );
    }
  };

  const handleParticipantJoined = ( event ) => {
    setRemoteDailyParticipantAuthId( event.participant.user_id );
    updateCallParticipants();
  };

  const handleParticipantLeft = ( event ) => {
    setRemoteDailyParticipantAuthId(
      prev => event.participant.user_id === prev ? null : prev,
    );
    updateCallParticipants();
  };

  // Whenever something changes in the Daily call, update our list of participants
  const updateCallParticipants = () => {
    // Set new Daily Participants value in state
    // TODO: Any filtering or modification we should make, here?
    setParticipants( Object.values( DailyCallObject.participants() ) );
  };

  /*
   * HELPERS - EXPORTED
   */

  // Set state values with info fetched from Firestore and RTD. Used in StartGame to populate roomInfo before loading the Play component
  const initializeCall = ( passedRoomInfo ) => {
    setRoomInfo( passedRoomInfo );
  };

  // Toggle local student mute status
  const toggleMute = () => {
    // set local audio to the opposite of what it currently is
    DailyCallObject.setLocalAudio( !DailyCallObject.localAudio() );
  };

  /*
   * RETURN
   */

  return (
    <CallContext.Provider
      value={{
        initializeCall,
        playAudio,
        toggleMute,
        callError,
        isMuted: ( () => !DailyCallObject.localAudio() )(),
        isRoomClosed,
        remoteDailyParticipantAuthId,
      }}
    >
      { children }
      <Audio participants={ participants } />
    </CallContext.Provider>
  );
};

export const useCallContext = () => useContext( CallContext );
