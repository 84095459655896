import styled from "styled-components/macro";

import { useGameContext } from "context/GameContext";

import TurnCountdown from "./TurnCountdown/TurnCountdown";
import TurnView from "./TurnView";

const ViewSliderContainer = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;

  background: white;
  position: relative;
  left: ${ props => props.countdownSecs ? "-100%" : "0" };
  margin: auto;
  transition: left 0.5s ease;
  width: 200%;
`;

/**
 * Displays the countdown to the turn start, or the player view for the turn, 
 * with a nifty little slide animation (if I say so myself) between them.
 *
 * @returns
 */
const ViewSlider = () => {

  const { countdownSecs } = useGameContext();

  return (
    <ViewSliderContainer countdownSecs={ countdownSecs }>
      <TurnView />
      <TurnCountdown />
    </ViewSliderContainer>
  );
};

export default ViewSlider;
