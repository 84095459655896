import styled from "styled-components/macro";

import { useGameContext } from "context/GameContext";

const TurnRecapContainer = styled.div`
  text-align: center;
`;

const RecapText = styled.p`
  color: ${ props => props.wasCorrect ? "green" : "red" };
`;

const TargetWord = styled.span`
  font-size: 1.25rem;
  font-weight: 600;
`;

const TranslatedWord = styled.span`
  font-weight: 300;
`;

/**
 * Shows what happened in the previous turn, or game rules (if displayed before
 * the first turn)
 * NOTE: While this component is visible, the turn number hasn't yet changed.
 * So even though the user sees this as information about the previous turn,
 * the code sees it as displaying information about the current turn
 */
const TurnRecap = () => {

  const {
    previousTurnInfo,
    turnCurrent,
  } = useGameContext();

  // Before turn 1, display game rules
  return turnCurrent === 1
    ? (
      <ul>
        <li>The Hint-giver gives hints</li>
        <li>The Guesser guesses</li>
        <li>You switch roles after each turn</li>
        <li>The goal is to correctly guess as many words as possible</li>
      </ul>
    )
    // After turn 1, display previous turn recap
    : (
      <TurnRecapContainer>
        <h2>
          {// Display correct or incorrect flag
            previousTurnInfo?.endState === "CORRECT"
              ? "Good job!"
              : "Not quite!"
          }
        </h2>
        <RecapText wasCorrect={ previousTurnInfo?.endState === "CORRECT" } >
          {// Adjust verbiage based on correct or incorrect guess
            previousTurnInfo?.endState === "CORRECT"
              ? "You correctly guessed "
              : "The word was "
          }
          <TargetWord>
            { previousTurnInfo?.word.es }
          </TargetWord>
          &nbsp;
          <TranslatedWord>
            &#40;{ previousTurnInfo?.word.en }&#41;
          </TranslatedWord>
        </RecapText>
      </TurnRecapContainer>
    );
};

export default TurnRecap;
