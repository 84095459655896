import styled from "styled-components/macro";

import { useGameContext } from "context/GameContext";

const WordsContainer = styled.ul`
  /* DISPLAY */
  display: flex;
  flex-flow: column wrap;

  list-style: none;
  margin: 0;
  padding: 0;

`;

const Word = styled.li`
  color: ${ ({ correct }) => correct ? "green" : "black" };
  font-weight: 500;
  list-style: none;
`;

/**
 * Display a list of the words used in completed turns thus far, styled to
 * indicate which were correctly guessed and which weren't
 */
const GameWords = () => {
  
  const { wordsUsed } = useGameContext();

  return (
    <WordsContainer>
      {// Render one Word for each previous turn
        wordsUsed.map( ({
          word, correct, 
        }, index) => (
          <Word
            key={ index }
            correct={ correct }
          >
            { word }
          </Word>
        ))
      }
    </WordsContainer>
  );
};

export default GameWords;
