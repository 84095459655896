import {
  useEffect,
  useRef,
} from "react";

import { getCurrentUser } from "context/UserContext";

// Given the output from DailyCallObject.participants(), render an <audio> element that plays the call audio
export const Audio = ({ participants }) => {

  /*
   * REF & HOOKS
   */

  // Ref to point to audio element
  const audioRef = useRef( null );

  const user = getCurrentUser();

  /*
   * USE EFFECT
   */

  // On load, add a stream as the source for the audio element
  useEffect(() => {

    // Protect against no value in ref
    if ( !audioRef?.current ) {
      return;
    }

    // Create new MediaStream and assign it as the source for the audio element
    audioRef.current.srcObject = new MediaStream();

  }, []);

  // When participants change, update track info
  useEffect(() => {

    // Protect against no value in audio ref, or no participants
    if (
      !audioRef?.current
      || !participants
      || !participants.length
    ) {
      return;
    }

    // Grab the audio element
    const audioElement = audioRef?.current;

    // Extract MediaStream that is source for audio
    const stream = audioElement?.srcObject;

    // If we don't have the audio, or the audio has no source, don't do anything
    if ( !stream ) {
      return;
    }

    // Convert the array of participant objects into an array of each participant's persistent audio track
    const tracks = participants.map(( participant ) =>
      // Don't get the local user's track (to avoid echo)
      participant.user_id === user.authId
        ? null
        : participant.tracks?.audio?.persistentTrack,
    );

    // Attach all participant tracks to the stream
    tracks.forEach((track) => {

      // A track could be undefined. Protect against that!
      if ( track ) {

        // Add listener to remove track when it ends (participant is no longer sending audio over it)
        track.addEventListener(
          "ended",
          ( event ) => stream.removeTrack( event.target ),
          // Only remove the track one time!
          { once: true },
        );

        // Add the track to the stream
        stream.addTrack( track );
      }
    });

    // Reset the audio element and get it ready to play
    audioElement.load();

    // Use helper to play the audio stream
    // Helper is async, but in useEffect, we fire and forget
    playStream( audioElement, stream );

  }, [ participants ]);

  /*
   * HELPERS
   */

  // Helper to play MediaStream that is source of audio element
  const playStream = async ( audioElement, stream ) => {
    // Demo has try/catch protection, so...
    try {
      if (
        // If at least one track is playable ...
        stream.getAudioTracks()?.some(( track ) => (
          track.enabled
          && track.readyState === "live"
        ))
        // ... and the audio is paused ...
        && audioElement.paused
      ) {
        // ... then play the audio!
        await audioElement.play();
      }
    }
    // If we got an error, log it
    catch (error) {
      console.error("Could not play audio stream for participants", error);
    }
  };

  /*
   * RENDER
   */

  return (
    <audio
      autoPlay
      playsInline
      ref={ audioRef }
    />
  );
};

// Export helper to try to directly play the Audio element
export const playAudio = async () => {

  // Grab audio element - it should be the only audio element in the DOM
  const audioElement = document.querySelector("audio");

  // If no element, log a warning
  if ( !audioElement ) {
    console.warn("No audio element found");
  }

  // Try to play the audio element
  try {
    await audioElement?.play();
  }
  catch (error) {
    // Log that play attempt failed
    console.error("Could not auto-play audio element", error);
  }
};
