import { useNavigate } from "react-router-dom";
import styled from "styled-components/macro";

import talkkaCrying from "assets/talkka_crying.gif";

import Button from "components/Button";

const ModalContainer = styled.div`
  /* DISPLAY */
  display: flex;
  align-items: center;
  justify-content: center;

  /* POSITION */
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  background: linear-gradient(rgba(180,180,180,0.8),rgba(180,180,180,0.8));
  z-index: ${ props => props.isVisible ? 10 : -10 };
`;

const ModalBody = styled.div`
  background: white;
  border-radius: ${ props => props.theme.borderRadius };
  box-shadow: ${ props => props.theme.boxShadow };
  box-sizing: border-box;
  padding: 1rem;
  position: relative;
  width: 67vw;

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    width: 90%;
  }
`;

const TalkkaImage = styled.img`
  float: right;
  width: 25%;
`;

const FailedToJoinModal = ({ isVisible }) => {

  const navigate = useNavigate();

  return isVisible
    ? (
      <ModalContainer isVisible={isVisible}>
        <ModalBody>
          <TalkkaImage src={ talkkaCrying } />
          <h1>Oh no!</h1>
          <p>
            Something went wrong while trying to join the game. Please click the button below to return to the lobby and try again.
          </p>
          <Button
            onClick={ () => navigate("/lobby", { replace: true }) }
            text="Back to Lobby"
          />
        </ModalBody>
      </ModalContainer>
    )
    : null;
};

export default FailedToJoinModal;
