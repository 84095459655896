import { useEffect } from "react";

import { useNavigate } from "react-router-dom";

import { getCurrentUser } from "context/UserContext";

/**
 * If there is a logged-in user, redirects the user to the Lobby instead of
 * rendering this component's children. Used to wrap the parent component for a
 * page (route) that logged-in users should NOT ever see.
 *
 * @param { Element } children - should usually just be the one rendered
 * component for a route
 * @returns
 */
const PushUserToLobby = ({ children }) => {

  const user = getCurrentUser();
  const navigate = useNavigate();

  useEffect(() => {
    if ( user ) {
      navigate("/lobby");
    }
  }, [ user ]);

  return children;
};

export default PushUserToLobby;
