import { useState } from "react";
import {
  BrowserRouter,
  Route,
  Routes,
} from "react-router-dom";
import { ErrorBoundary } from "react-error-boundary";

import { CallContextProvider } from "context/CallContext";
import { GameContextProvider } from "context/GameContext";
import { UserContextProvider } from "context/UserContext";

import ErrorFallback from "components/ErrorFallback";
import Layout from "components/Layout";
import PushUserToLobby from "components/PushUserToLobby";
import RequireUser from "components/RequireUser";
import VersionCheck from "components/VersionCheck";

import CreateAnonymousUser from "pages/CreateAnonymousUser";
import FourOhFour from "pages/FourOhFour";
import FrontDoor from "pages/FrontDoor";
import Lobby from "pages/Lobby";
import Play from "pages/Play";
import StartGame from "pages/StartGame";

import { talkka } from "services/logging";

// Render the routes that run the app
const App = () => {

  // State for show/hide modal lives here, to pass setter to children. Modal is rendered in Layout
  const [ showHowToPlayModal, setShowHowToPlayModal ] = useState( false );
  const [ lockHowToPlayModal, setLockHowToPlayModal ] = useState( false );

  return (
    <ErrorBoundary
      FallbackComponent={ ErrorFallback }
      onError={ (error, info) => talkka.error(error, info) }
      onReset={ () => window.location.reload() }
    >
      <VersionCheck>
        <UserContextProvider>
          <BrowserRouter>
            <Layout
              lockHowToPlayModal={ lockHowToPlayModal }
              setLockHowToPlayModal={ setLockHowToPlayModal }
              setShowHowToPlayModal={ setShowHowToPlayModal }
              showHowToPlayModal={ showHowToPlayModal }
            >
              <Routes>
                <Route
                  path="/"
                  element={
                    <PushUserToLobby>
                      <FrontDoor />
                    </PushUserToLobby>
                  }
                />
                <Route
                  path="/login"
                  element={
                  // TODO NOTE: no longer pushing user to lobby on this route. Speak App sends students here with a preferred bundle ID, name, and email, which we need to pick up fresh for offers
                    <CreateAnonymousUser />
                  }
                />
                <Route
                  path="/lobby"
                  element={
                    <RequireUser>
                      <Lobby />
                    </RequireUser>
                  }
                />
                <Route
                  path="/startGame/:player1"
                  element={
                    <RequireUser>
                      <StartGame />
                    </RequireUser>
                  }
                />
                <Route
                  path="/play/:roomName"
                  element={
                    <RequireUser>
                      <CallContextProvider>
                        <GameContextProvider>
                          <Play
                            setShowHowToPlayModal={ setShowHowToPlayModal }
                            setLockHowToPlayModal={ setLockHowToPlayModal }
                          />
                        </GameContextProvider>
                      </CallContextProvider>
                    </RequireUser>
                  }
                />
                {/* Fallback to 404 component for unmatched route */}
                <Route
                  path="*"
                  element={ <FourOhFour /> }
                />
              </Routes>
            </Layout>
          </BrowserRouter>
        </UserContextProvider>
      </VersionCheck>
    </ErrorBoundary>
  );
};

export default App;
