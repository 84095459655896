import styled from "styled-components/macro";

import talkkaCrying from "assets/talkka_crying.gif";

import versionJSON from "version/version";
const { version } = versionJSON;

/*
 * Many of the styles here are copied from theme and global styles in
 * Layout.js, since we don't have access to those things, here. (See
 * ErrorFallback JSDoc)
 */

const ErrorFallbackContainer = styled.main`
  font-family: Fredoka, sans-serif;

  button {
    font-family: Fredoka, sans-serif;
  }
`;

const ErrorFloater = styled.div`
  border: 1px solid #fff8;
  border-radius: 1rem;
  box-shadow: 0 5px 15px 1px rgba(0, 0, 0, .1);
  box-sizing: border-box;
  margin: 1rem auto;
  padding: 1rem;
  width: 67vw;
  max-width: 980px;
  min-width: 300px;
`;

const TalkkaImage = styled.img`
  float: right;
  width: 8rem;
`;

const ErrorHeading = styled.h1`
  color: #F79544;
  font-family: Fredoka One, sans-serif;
  font-weight: 400;
  text-shadow: 2px 4px 3px rgba(0,0,0,0.1);
`;

// Copied from Button component, same reason as above
const ErrorButton = styled.button`
  background: #00bbc9;
  border: 1px solid #fff8;
  border-radius: 1rem;
  box-shadow: 0 5px 15px 1px rgba(0, 0, 0, .1);
  color: white;
  cursor: pointer;
  display: inline-block;
  font-size: 1.25rem;
  font-weight: 500;
  padding: 0.5rem 1.5rem;
  text-shadow: 2px 4px 3px rgba(0,0,0,0.1);
  transition: transform 0.25s ease;

  &:hover {
    transform: scale(1.1);
  }
  &:active:hover, &:active {
    transform: translate(-0.1rem, 0.25rem);
  }
`;

const ErrorVersion = styled.div`
  font-size: 0.75rem;
  text-align: right;
`;

/**
 * Render a message when an error that would otherwise cause a WSOD occurs
 *
 * NOTE: This component is upstream of everything else, so doesn't have access
 * to styling theme or react-router-dom hooks like useLocation (since they rely
 * on being inside a router)
 */
const ErrorFallback = ({ resetErrorBoundary }) => {
  return (
    <ErrorFallbackContainer>
      <ErrorFloater>
        <TalkkaImage src={ talkkaCrying } />
        <ErrorHeading>Oh no!</ErrorHeading>
        <p>
          Something went wrong with the game.
        </p>
        <div>
          <p>
            You can restart and try again by clicking the button below.
          </p>
          <ErrorButton onClick={ resetErrorBoundary }>
            Try Again
          </ErrorButton>
        </div>
        <div>
          <p>
            Talkkaround is in the beta phase, meaning that we're still finalizing the application, adding functionality, and expanding device support.
          </p>
          <p>
            Our system has automatically noted the error you encountered so we can work on a fix, but we'd love to hear your feedback! Click the button below to email us with any information or context you think might be helpful as we work to ensure that this doesn't happen again.
          </p>
          <ErrorButton onClick={() => window.location.href = `mailto:amigos@beepboop.us?subject=Talkkaround Feedback (Error ${ version })`}>
          Email us
          </ErrorButton>
        </div>
        <ErrorVersion>
          { version }
        </ErrorVersion>
      </ErrorFloater>
    </ErrorFallbackContainer>
  );
};

export default ErrorFallback;
