import styled from "styled-components/macro";

const StyledButton = styled.button`
  background: ${ props => {
    if ( props.disabled ) {
      return "silver";
    }
    return props.secondary ? props.theme.turquoise : props.theme.orange;
  }};
  border: 1px solid #fff8;
  border-radius: ${ props => props.theme.borderRadius };
  box-shadow: ${ props => props.theme.boxShadow };
  color: ${ props => props.textColor ? props.textColor : "white" };
  cursor: ${ props => props.disabled ? "not-allowed" : "pointer" };
  display: inline-block;
  font-size: 1.25rem;
  font-weight: 500;
  padding: 0.5rem 1.5rem;
  text-shadow: ${ props => props.theme.textShadow };
  transition: transform 0.25s ease;

  ${ props => props.buttonStyle ? props.buttonStyle : ""}

  ${ props => (
    props.disabled
      ? null
      : `
        &:hover {
          transform: scale(1.1);
        }
        &:active:hover, &:active {
          transform: translate(-0.1rem, 0.25rem);
        }
      `
  )}
`;

/**
 * Generic button, takes props to adjust style/color
 */
const Button = ({
  onClick = () => console.log("click"),
  text = "Click me",
  buttonStyle = null,
  secondary = false,
  disabled = false,
  ...moreProps
}) => {
  return (
    <StyledButton
      onClick={ () => disabled ? null : onClick() }
      buttonStyle={ buttonStyle }
      disabled={ disabled }
      secondary={ secondary }
      { ...moreProps }
    >
      { text }
    </StyledButton>
  );
};

export default Button;
