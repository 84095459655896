import {
  useEffect,
  useState,
} from "react";
import {
  onValue,
  ref,
} from "firebase/database";

import { GAMES_PREFIX } from "const";

import { firebaseRealTimeDatabase } from "services/firebase";

/**
 * Central place to get basic info for all games currently ongoing
 */
export const useGames = () => {
  /*
   * STATE
   */

  const [ games, setGames ] = useState( [] );

  /*
   * USE EFFECT
   */

  // On load, set up subscription to game info
  useEffect(() => {

    const allGamesRef = ref(
      firebaseRealTimeDatabase,
      GAMES_PREFIX,
    );

    const unsubscribeFromGames = onValue(
      allGamesRef,
      ( snap ) => {

        // Extract object with all games from RTD
        const allGamesObject = snap.val();

        // Set array of all games in state
        setGames( allGamesObject ? Object.values(allGamesObject) : [] );
      },
    );

    return unsubscribeFromGames;

  }, []);

  return {
    allGames: games,
    gameCount: games.length,
  };
};
