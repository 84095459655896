import React, {
  useEffect, useState, 
} from "react";

import styled from "styled-components/macro";

import Daily from "@daily-co/daily-js";

//import MegaTalkka from "../../assets/talkka_megaphone.png";

import activebuildconfig from "../../configs/activebuildconfig.json";
const { APP_POINTER } = activebuildconfig;

import { getVersionCheck } from "../../services/datastore";

import versionJSON from "version/version";
const { version } = versionJSON;

const ModalBackground = styled.div`
  /* DISPLAY */
  display: flex;
  align-items: center;
  justify-content: center;

  /* POSITION */
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  background: linear-gradient(rgba(180,180,180,0.8),rgba(180,180,180,0.8));
  z-index: ${ props => props.isVisible ? 10 : -10 };
`;

const Modal = styled.div`
  background: white;
  border-radius: ${ props => props.theme.borderRadius };
  box-shadow: ${ props => props.theme.boxShadow };
  box-sizing: border-box;
  padding: 1rem;
  position: relative;
`;

const OutdatedButton = styled.div`
  background-color: #BD5700;
  border-radius: 1000px;
  color: white;
  cursor: pointer;
  display: flex;
  font-size: 18px;
  font-weight: 600;
  margin: .75em auto;
  min-width: 90px;
  padding: .75em 1.25em;
  text-align: center;
  transition: all .2s ease;
  width: fit-content;
`;

/**
 * Component for alerting students that they aren't using the latest version of the Play App * 
 * @param { Object } children - contents of the page
 */
const VersionCheck = ({ children }) => {
  const [ checkingVersion, setCheckingVersion ] = useState(true);
  const [ showVersionCheckModal, setShowVersionCheckModal ] = useState(false);
  const [ versionData, setVersionData ] = useState([]);
  const [ showProxyCheckModal, setShowProxyCheckModal ] = useState(false);
  const [ requesterIP, setrequesterIP ] = useState("");

  const userAgent = window.navigator.userAgent;
  
  let isBeepboopSupportedBrowser = true;

  // block Internet Explorer 10 & below (MSIE) and Internet Explorer 11 & up (Trident)
  // block Samsung & Mi Browser (EdgW)
  // block Firefox (at least on Firefox for Mac, when focus is lost, it doesn't respond to microphone deactivation/reactivation requests & it aggressively kills sockets & disconnects from WebRTC servers, so taking a sledgehammer)
  // block Miui Browser
  // block Android Webview
  if (userAgent.indexOf("MSIE") > 0 
    || userAgent.indexOf("Trident") > 0
    || userAgent.indexOf("SamsungBrowser") > 0 
    || userAgent.indexOf("EdgW") > 0
    || userAgent.indexOf("Firefox") > 0
    || userAgent.indexOf("MiuiBrowser") > 0
    || userAgent.indexOf("; wv") > 0
  ) {
    isBeepboopSupportedBrowser = false;
  }
  
  // isBrowserSupported if browser is NOT supported by Daily
  const isBrowserSupported = Daily.supportedBrowser().supported && isBeepboopSupportedBrowser;
  
  const areCookiesEnabled = navigator.cookieEnabled;
  
  /*
   * USE EFFECT
   */
  // fetch version check from server
  async function asyncFetchVersionCheck() {

    // send request to backend to get version check info
    const versionCheckData = await getVersionCheck(version);

    setVersionData(versionCheckData);
  
    // set student object in studentData state
    if (versionCheckData?.forceRefresh) {
      setShowVersionCheckModal(true);
    }

    setrequesterIP(versionCheckData?.requesterIP);

    // determine if student is using a proxied IP (i.e. VPN, browser mini proxy)
    // example: 105.111.183.151,82.146.208.178
    if (versionCheckData?.requesterIP?.includes(",")) {
      setShowProxyCheckModal(true);
    }
  }

  // On component load, check to see if Student is on current version
  useEffect(() => {
    // Only check version if not on local dev. This ensures that feature branches cut from old versions can be run locally for development & acceptance testing
    if ( APP_POINTER !== "DEV" ) {
      // initial fetchVersionCheck call
      async function fetchVersionCheck() {
        try {
          await Promise.all([
            asyncFetchVersionCheck(),
          ]).catch(err => {
            console.log(err);
          });

          setCheckingVersion(false);
        }
        catch {
          console.log("Error fetching version check");
        }
      }
      fetchVersionCheck();
    }
    // If on dev, we just need to set checkingVersion to false
    else {
      setCheckingVersion(false);
    }
  }, []);

  // On button click, reload current page
  const bustCacheAndReload = () => {
    
    window.location.reload();
  };

  // only allow to proceed after version has been checked
  if (checkingVersion) {
    // if version hasn't been checked yet, don't return children
    return null;
  }
  else if (!isBrowserSupported) {
    // if student is attempting to log in from a browser that doesn't have cookies enabled
    return ( 
      <ModalBackground
        id="browserCheckModalBackground"
        isVisible={ true }
      >
        <Modal>
          <div>      
            <div className="supportInfo">Unfortunately, your browser is not compatible with the Talkkaround Game.<br/><br/>
              <a target="_blank" rel="noreferrer noopener" href="https://www.google.com/chrome/" className="downloadRedirectButton" >
              We recommend Google Chrome</a><br/><br/>
              Need help? Email us at <a className="emailLink" target="_blank" href={"mailto:amigos@beepboop.us?subject=Talkkaround Player Needs Help: Incompatible Browser&body=Technical Details for Incompatible Browser: " + userAgent}>amigos@beepboop.us</a>
            </div>
          </div>
        </Modal>
      </ModalBackground>
    );
  }
  else if (!areCookiesEnabled) {
    // if player is attempting to log in from a browser that doesn't have cookies enabled
    return ( 
      <ModalBackground
        id="cookieCheckModalBackground"
        isVisible={ true }
      >
        <Modal>
          <div>      
            <div className="supportInfo">To play the Talkkaround Game, you must enable cookies<br/><br/>
              <a target="_blank" rel="noreferrer noopener" href="https://www.whatismybrowser.com/guides/how-to-enable-cookies/" className="downloadRedirectButton" >
              Click to Learn How</a><br/><br/>
              Need help? Email us at <a className="emailLink" target="_blank" href={"mailto:amigos@beepboop.us?subject=Talkkaround Player Needs Help: Cookies Disabled&body=Technical Details for Browser: " + userAgent}>amigos@beepboop.us</a>
            </div>
          </div>
        </Modal>
      </ModalBackground>
    );
  }
  else if (showProxyCheckModal) {
    // if player is attempting to log in using a proxied IP, display "disable VPN" modal
    return ( 
      <ModalBackground
        id="proxyCheckModalBackground"
        isVisible={ true }
      >
        <Modal>
          <div>
            <div className="supportInfo">
              Unfortunately, the Talkkaround Game is not joinable if you're using a VPN, firewall, or other proxy.
              <br/><br/>
              If you still see this message after disabling your VPN or Firewall and refreshing this page, 
              email us at <a className="emailLink" target="_blank" href={"mailto:amigos@beepboop.us?subject=Talkkaround Player Needs Help: VPN, Firewall, or Proxy IP Detected&body=Technical Details: " + requesterIP + " | " + userAgent}>amigos@beepboop.us</a>
            </div>
          </div>
        </Modal>
      </ModalBackground>
    );
  }
  else if (showVersionCheckModal) {
    // if version is behind current server Play App version, display "must get new version" modal
    return ( 
      <ModalBackground
        id="versionCheckModalBackground"
        isVisible={ true }
      >
        <Modal>
          <div>
            <div className="supportInfo">
              You are on {versionData.clientVersion} of the Talkkaround Game
              <br/><br/>
              There is a new version available ({versionData.serverVersion})
              <OutdatedButton
                onClick={bustCacheAndReload}
              >
                Tap to Get New Version
              </OutdatedButton>
              Or try one of these keyboard combinations:
              <br/>
              Ctrl-Shift-R (Windows)
              <br/>
              Command-Shift-R (Mac)
            </div>
          </div>
        </Modal>
      </ModalBackground>
    );
  }
  else {
    // proceed with mounting additional children because version has been checked and is high enough
    return (<>{children}</>);
  }
};

export default VersionCheck;
